import * as React from 'react'
import { useApiClient } from '../utils/use-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'
import { ToggleButton } from './toggle-button'
import { store } from 'store/index'

type OrgPublicToggleProps = {
  orgId: number
  publicFramework: boolean
}

export const OrgPublicToggle: React.VFC<OrgPublicToggleProps> = (props) => {
  const { orgId, publicFramework } = props

  const [isPublic, setIsPublic] = React.useState(publicFramework)
  const client = useApiClient()

  const onChange = async () => {
    try {
      const result = await client.patch<{ notice: string }>(`/orgs/${orgId}`, {
        called_by: 'organisation_home',
        public_framework: !isPublic,
      })
      successToast(result.notice)
      setIsPublic(!isPublic)
      await store.orgs.fetchAll()
    } catch (e) {
      errorToast()
    }
  }

  return <ToggleButton checked={isPublic} onChange={onChange} />
}
