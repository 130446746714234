import { Clipboard } from '@phosphor-icons/react'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

export const CheckinsLink = () => {
  const path = `/check-ins`
  const title = 'Check-ins'

  return (
    <SideMenu.LinkItem
      active={useIsActive(path)}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
      href={path}
      icon={Clipboard}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
