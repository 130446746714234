import * as React from 'react'
import cn from 'classnames'
import { NotificationItemVm } from '../notification-item-vm'
import { iconMap } from './icon-map'

export type NotificationIconProps = {
  vm: NotificationItemVm
  className?: string
}

export const NotificationIcon: React.VFC<NotificationIconProps> = (props) => {
  const { vm, className } = props

  const Icon = iconMap[vm.notificationType]

  return (
    <div
      className={cn(
        vm.showAvatar ? 'absolute top-4 left-4 w-5 h-5' : 'w-8 h-8',
        ' bg-white text-gray-900 border border-gray-100 rounded-full flex items-center justify-center',
        className
      )}
    >
      <Icon
        weight="bold"
        className={cn(vm.showAvatar ? 'w-2.5 h-2.5' : 'w-3.5 h-3.5')}
      />
    </div>
  )
}
