import * as React from 'react'
import { useApiClient } from '../utils/use-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'
import { ToggleButton } from './toggle-button'

type SlackPreferenceToggleProps = {
  orgId: number
  enabled: boolean
  preferenceKey: string
}

type PreferencesMap = { [key: string]: boolean }

export const SlackPreferenceToggle: React.VFC<SlackPreferenceToggleProps> = (
  props
) => {
  const { orgId, enabled, preferenceKey } = props

  const [isEnabled, setisEnabled] = React.useState(enabled)
  const client = useApiClient()

  const onChange = async () => {
    const preferences: PreferencesMap = {}
    preferences[preferenceKey] = !isEnabled

    const payload = {
      org_id: orgId,
      preferences,
    }

    try {
      const result = await client.patch<{ notice: string }>(
        `/api/v1/internal/integrations/slack`,
        payload
      )
      successToast(result.notice)
      setisEnabled(!isEnabled)
    } catch (e) {
      errorToast()
    }
  }
  return <ToggleButton checked={isEnabled} onChange={onChange} />
}
