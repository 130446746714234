import { CaretDown, CaretUp, Stack, Plus } from '@phosphor-icons/react'
import * as Collapsible from '@radix-ui/react-collapsible'
import React from 'react'
import { CreateFormModalButton } from 'components/create-form-modal'
import * as SideMenu from 'components/side-menu'
import { useStore } from 'store/context'
import { ActionsLink } from './actions-link'
import { StoriesLink } from './stories-link'
import { ReflectionsLink } from './reflections-link'
import { FeedbackLink } from './feedback-link'
import { WinsLink } from './wins-link'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'

export const UpdatesAccordion = () => {
  const { currentUser } = useStore()

  const localStorageKey = 'updates-accordion-open'

  const [open, setOpen] = React.useState<boolean>(
    localStorage.getItem(localStorageKey) !== 'false'
  )

  const onClick = React.useCallback(() => {
    const newMode = !open
    setOpen(newMode)
    localStorage.setItem(localStorageKey, `${newMode}`)
  }, [open])

  const CaretIcon = open ? CaretUp : CaretDown

  const activityPath = '/feed?all=true'
  const isActive = useIsActive(activityPath, null, { includeSearch: true })

  if (!currentUser) return null

  return (
    <div className="relative">
      <Collapsible.Root open={open} onOpenChange={(open) => setOpen(!!open)}>
        <div className="flex gap-2">
          <SideMenu.LinkItem
            icon={Stack}
            active={isActive}
            analyticsProps={sideNavAnalyticsProps({
              title: 'All activity',
              href: activityPath,
            })}
            href={activityPath}
          >
            Activity
          </SideMenu.LinkItem>
          <Collapsible.Trigger
            onClick={onClick}
            className="absolute right-7 top-1 h-6 w-6 flex-grow-1 bg-transparent rounded flex items-center justify-center border-transparent hover:border-gray-100 border-px border-solid flex-shrink-0"
          >
            <div className="px-1">
              <CaretIcon className="h-3 text-gray-400 w-3" weight="bold" />
            </div>
          </Collapsible.Trigger>
        </div>
        <Collapsible.Content className="border-0 border-gray-100 border-l border-solid ml-[18px] pl-[9px]">
          <ReflectionsLink />
          <StoriesLink />
          <ActionsLink />
          <FeedbackLink />
          <WinsLink />
        </Collapsible.Content>
      </Collapsible.Root>
      <div className="absolute h-6 right-0 top-0 p-1">
        <CreateFormModalButton
          title="Add update"
          user={currentUser}
          source={'side_nav'}
          className="min-w-2 w-6 h-6 bg-transparent rounded flex items-center justify-center border-transparent hover:border-gray-100 border-px border-solid flex-shrink-0"
          label={
            <div className="px-1">
              <Plus className="h-3 text-gray-400 w-3" weight="bold" />
            </div>
          }
        />
      </div>
    </div>
  )
}
