import React from 'react'
import { Org } from 'store/modules/orgs'
import { Team } from 'store/modules/teams'
import { TeamAccordionLink } from './team-accordion-link'
import { User } from 'store/modules/users'
import { observer } from 'mobx-react-lite'

type TeamsAccordionContentProps = {
  org: Org
  teamCount?: number
  teams: Team[]
  user: User
}

export const TeamsAccordionContent: React.FC<TeamsAccordionContentProps> =
  observer((props) => {
    const { teamCount, teams, user } = props

    const membership = user.membership

    const sortedTeams = teams.sort((a, b) => (a.name > b.name ? 1 : -1))

    const otherTeamCount = teamCount ? teamCount - teams.length : 0

    if (!user) return null

    return (
      <>
        {sortedTeams.map((team) => (
          <TeamAccordionLink
            team={team}
            user={user}
            key={`team-accordion-link-${team.id}`}
          />
        ))}
        {teams.length === 0 && otherTeamCount === 0 && <p>No teams</p>}
      </>
    )
  })
