import { ArrowRight } from '@phosphor-icons/react'
import { Link, Loader } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ComparePositionSectionVm } from './compare-position-section-vm'
import { ComparePositionSelect } from './compare-position-select'
import { SkillsRadarChart } from 'components/skills-radar-chart'
import { User } from 'store/modules/users'
import { useStore } from 'store/context'

type ComparePositionSectionProps = {
  user: User
}

export const ComparePositionSection = observer(
  (props: ComparePositionSectionProps) => {
    const { user } = props

    const { positions, positionSkills } = useStore()

    const positionList = positions.forOrg
    const wrapper = React.useRef<HTMLDivElement>(null)

    const [positionOneId, setPositionOneId] = React.useState<
      string | undefined
    >(undefined)

    const [positionTwoId, setPositionTwoId] = React.useState<
      string | undefined
    >(undefined)

    React.useEffect(() => {
      if (!wrapper.current) return
      wrapper.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, [positionOneId, positionTwoId])

    const vm = React.useMemo(() => {
      return new ComparePositionSectionVm(positionList, user)
    }, [positionList, user])

    React.useEffect(() => {
      const fetchPositions = async () => {
        const defaultPositionOneId = vm.defaultPositionOneId
        if (defaultPositionOneId) {
          vm.fetchPositionSkills(defaultPositionOneId)
          setPositionOneId(defaultPositionOneId)
        }
      }

      fetchPositions()
    }, [positionList, vm])

    return (
      <>
        <div className="p-6" ref={wrapper}>
          <div className="flex items-center justify-between mb-5">
            <div className="font-bold text-base text-gray-900">
              Compare positions
            </div>
            <div className="flex gap-2">
              <Link
                className="flex items-center justify-center"
                href={vm.href(positionOneId, positionTwoId)}
                underline={false}
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                <ArrowRight className="h-4 text-gray-200 w-4" weight="bold" />
              </Link>
            </div>
          </div>
          <div className="flex justify-center mb-4 relative">
            {positions.loading && (
              <div className="flex justify-center absolute inset-0">
                <Loader />
              </div>
            )}
            <SkillsRadarChart
              series={vm.series(positionOneId, positionTwoId)}
              size={205}
            />
            {!positionSkills.loading &&
              vm.emptyStateBannerText(positionOneId, positionTwoId) && (
                <div
                  className="absolute bg-gray-50 bottom-0 left-0 m-auto px-3 py-1 right-0 rounded text-center text-xs top-0 w-[162px]"
                  style={{ height: 'fit-content' }}
                >
                  {vm.emptyStateBannerText(positionOneId, positionTwoId)}
                </div>
              )}
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <span
              className="h-2.5 rounded-full w-2.5"
              style={{ background: vm.colourOne }}
            ></span>
            {vm.hasPositions ? (
              <ComparePositionSelect
                value={positionOneId || vm.defaultPositionOneId}
                onChange={(positionId) => {
                  if (positionId) {
                    setPositionOneId(positionId)
                    vm.applyPositionChange(positionId)
                  }
                }}
                options={vm.options}
              />
            ) : (
              <span className="text-gray-600 text-xs">You (No position)</span>
            )}
          </div>
          <div className="flex gap-1.5 items-center">
            <span
              className="h-2.5 rounded-full w-2.5"
              style={{ border: `1px solid ${vm.colourTwo}` }}
            ></span>
            {vm.hasPositions ? (
              <ComparePositionSelect
                value={positionTwoId}
                onChange={(positionId) => {
                  if (positionId) {
                    setPositionTwoId(positionId)
                    vm.applyPositionChange(positionId)
                  }
                }}
                options={vm.options}
              />
            ) : (
              <span className="text-gray-600 text-xs">
                No positions to compare
              </span>
            )}
          </div>
        </div>
      </>
    )
  }
)
