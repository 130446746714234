import * as React from 'react'
import { useModalContext } from 'src/design-system'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { EVENT_KEY_NAME } from 'components/get-started-modal/shared'
import megAndTimAvatars from '../../../../images/meg-and-tim-avatars.png'

export const BookADemo = () => {
  const { closeModal } = useModalContext()

  const onScriptLoad = () => {
    window.SavvyCal =
      window.SavvyCal ||
      function (...args: unknown[]) {
        window.SavvyCal.q = window.SavvyCal.q || []
        window.SavvyCal.q.push(...args)
      }
    window.SavvyCal('init')
  }

  React.useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://embed.savvycal.com/v1/embed.js'
    script.async = true
    script.onload = () => onScriptLoad()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const onClickHandler = () => {
    trackEvent(EVENT_KEY_NAME, {
      title: 'Get help from our team',
      href: 'https://savvycal.com/progression/product-demo?origin=upgrade_banner',
    })

    closeModal()
  }

  return (
    <a
      data-savvycal-embed
      href="https://savvycal.com/progression/product-demo?origin=upgrade_banner"
      onClick={onClickHandler}
      className="flex-1 flex flex-row gap-x-4 items-center border-px border-solid border-gray-100 hover:border-gray-200 rounded-lg p-4 transition-colors"
    >
      <div className="rounded bg-pink-100 text-pink-600 w-20 flex-shrink-0 h-full flex items-center justify-center">
        <img src={megAndTimAvatars} alt="" className="w-14" />
      </div>
      <div className="flex flex-col gap-y-1">
        <h3 className="font-bold text-gray-900 text-sm">
          Get help from our team
        </h3>
        <p className="text-gray-600 text-xs mb-0">
          Talk through your needs in detail by booking a demo with our team of
          experts.
        </p>
      </div>
    </a>
  )
}
