import { ArrowSquareOut, ChatCircleDots } from '@phosphor-icons/react'
import React from 'react'
import { Dropdown } from 'src/design-system'

type ProductFeedbackLinkProps = {
  fullName: string
  email: string | null
}

export const ProductFeedbackLink: React.FC<ProductFeedbackLinkProps> = (
  props
) => {
  const { fullName, email } = props
  const title = 'Product Feedback'

  let feedbackPath = `https://airtable.com/shrMZXwkilntRE9BA?prefill_name=${fullName}`
  if (email) feedbackPath += `&prefill_email=${email}`

  return (
    <Dropdown.Link
      className="text-left text-gray-900 min-w-48 flex justify-between items-center gap-1 pl-2"
      href={feedbackPath}
      isExternal
    >
      <div className="flex gap-2 items-center">
        <ChatCircleDots
          weight="bold"
          className="shrink-0 h-3.5 fill-gray-500 w-5"
          aria-hidden
        />
        {title}
      </div>
      <ArrowSquareOut className="text-gray-200" />
    </Dropdown.Link>
  )
}
