import * as React from 'react'
import { ComponentPropsWithoutRef, FC } from 'react'
import classNames from 'classnames'

type IndicatorProps = ComponentPropsWithoutRef<'div'>

export const UnreadIndicator: FC<IndicatorProps> = ({ className }) => {
  return (
    <div
      className={classNames(className, 'bg-red-500 h-2.5 rounded-full w-2.5')}
    >
      <span className="sr-only">Unread</span>
    </div>
  )
}
