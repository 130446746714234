import * as React from 'react'
import cn from 'classnames'
import { Avatar } from 'src/design-system'
import { sideNavAnalyticsProps } from 'components/side-nav/side-nav-analytics-props'
import * as SideMenu from 'components/side-menu'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import { useSideNavContext } from '../side-nav-context'
import { observer } from 'mobx-react-lite'

export type UserLinkProps = {
  avatarUrl: string | null
  initials: string
  name: string
  slug: string
}

export const UserLink: React.VFC<UserLinkProps> = observer((props) => {
  const { avatarUrl, initials, name, slug } = props

  const profilePath = `/users/${slug}`
  const active = useIsActive(profilePath)

  const { isOpen } = useSideNavContext()

  return (
    <div className={cn('group relative', !isOpen && 'max-w-max')}>
      <SideMenu.LinkItem
        active={active}
        analyticsProps={sideNavAnalyticsProps({
          title: 'User',
          href: profilePath,
          clickedFromCollapsedSideMenu: !isOpen,
        })}
        className={cn(!isOpen && 'pr-1')}
        href={profilePath}
        showActiveStyling={isOpen}
      >
        <Avatar
          alt={`Avatar for ${name}`}
          className="border-gray-100 border-px border-solid"
          fallbackText={initials}
          size="xs"
          src={avatarUrl}
        />

        <div className="truncate text-sm max-w-[calc(100%-45px)]">Home</div>
      </SideMenu.LinkItem>
    </div>
  )
})
