import * as React from 'react'
import cn from 'classnames'
import { Logo } from 'components/atoms/logo'

export type LogoLinkProps = {
  className?: string
}

export const LogoLink: React.VFC<LogoLinkProps> = (props) => {
  const { className } = props

  return (
    <a className={cn('ml-1.5', className)} href="/">
      <Logo className="h-7" />
    </a>
  )
}
