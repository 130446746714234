import * as React from 'react'
import { CheckinStageCompleteNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type CheckinStageCompleteProps = {
  notification: CheckinStageCompleteNotification
}

export const CheckinStageCompleteContent: React.VFC<
  CheckinStageCompleteProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        Your Check-in
        {notification.author && (
          <>
            {' '}
            with{' '}
            <strong className="font-bold">
              {notification.author.fullName}
            </strong>
          </>
        )}{' '}
        is ready for joint review
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">Start joint Check-in</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
