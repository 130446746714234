import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  CheckinsLink,
  ComparePositionsLink,
  OrgLink,
  ReportsLink,
  UserLink,
} from '..'
import { useStore } from 'store/context'
import * as SideMenu from 'components/side-menu'
import { PackageProtect } from 'components/package-protect'
import { UpdatesAccordion } from '../updates-accordion'

export const DefaultSection = observer(() => {
  const { currentUser } = useStore()

  if (!currentUser) return null

  const { org, slug } = currentUser

  return (
    <>
      <SideMenu.MenuSection data-element-id="side-nav-your-menu-section">
        <UserLink
          avatarUrl={currentUser.avatarUrl}
          initials={currentUser.initials}
          name={currentUser.fullName}
          slug={slug}
        />
        <PackageProtect>
          {currentUser.isManager && (
            <ReportsLink reports={currentUser.directReports} userSlug={slug} />
          )}
        </PackageProtect>
        <PackageProtect>
          <CheckinsLink />
          <UpdatesAccordion />
        </PackageProtect>
        <ComparePositionsLink />
        {org && (
          <OrgLink
            isAdmin={currentUser.isAdmin}
            orgSlug={org.slug}
            orgAvatarUrl={org.avatarUrl}
            orgName={org.name}
          />
        )}
      </SideMenu.MenuSection>
    </>
  )
})
