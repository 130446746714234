import * as React from 'react'
import cn from 'classnames'
import { NotificationItemAvatar } from './avatar'
import { contentMap } from './content'
import { NotificationItemVm } from './notification-item-vm'
import { Notification } from '../../../store/modules/notifications'
import { NotificationIcon } from './icon'
import { UnreadIndicator } from '../unread-indicator'
import { observer } from 'mobx-react-lite'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export type NotificationItemProps = {
  notification: Notification
  showBottomBorder?: boolean // TODO: remove in favour of divide-y with TailwindCSS v3
}

export const NotificationItem = observer((props: NotificationItemProps) => {
  const { notification, showBottomBorder = true } = props

  const onClick = React.useCallback(
    async (e) => {
      e.preventDefault()
      trackEvent('$track_notification_item_clicked', {
        notificationType: notification.notificationType,
      })
      await notification.persistAsRead()
      window.location.href = notification.url
    },
    [notification]
  )

  const vm = new NotificationItemVm(notification)

  const content = contentMap(notification)

  return (
    <a
      className="flex flex-row items-center hover:bg-gray-50 px-2 text-gray-900"
      href={notification.url}
      onClick={onClick}
    >
      {vm.showUnreadIndicator && <UnreadIndicator />}
      <div
        className={cn(
          vm.showUnreadIndicator ? 'ml-2' : 'ml-4',
          showBottomBorder && 'border-0 border-b border-solid border-gray-100',
          'w-full flex flex-1 py-4 items-center mr-4'
        )}
      >
        <div className="relative">
          <NotificationItemAvatar vm={vm} />
          <NotificationIcon vm={vm} />
        </div>
        <div className="ml-3 w-full">{content}</div>
      </div>
    </a>
  )
})
