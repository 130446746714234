import { ConfirmationDialog, useTimedContent } from 'src/design-system'
import { Flag, X } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { trackEvent } from '../../services/event-tracker'
import { observer } from 'mobx-react-lite'
import { GetStartedVm } from './get-started-vm'

type GetStartedModalButtonProps = {
  className?: string
  onOpenChange?(open: boolean): void
}

export const GetStartedModalButton = observer(
  (props: GetStartedModalButtonProps) => {
    const { className, onOpenChange } = props

    const { hideFor, show } = useTimedContent('hide-get-started-modal-button')

    const vm = new GetStartedVm()

    if (!show || !vm.showGetStarted) return null

    const openGetStartedModal = () => {
      onOpenChange?.(true)
      trackEvent('$track_get_started_modal_button_clicked')
    }

    const hideGetStartedModal = () => {
      trackEvent('$track_get_started_modal_button_hidden')
      hideFor('forever')
      onOpenChange?.(false)
    }

    return (
      <ConfirmationDialog.Root
        body="Are you sure you want to permanently hide the Get Started screen? This can’t be undone."
        onConfirm={hideGetStartedModal}
        confirmLabel="Hide"
        title="Hide Get Started?"
      >
        <div
          className={cn(
            'rounded relative w-full min-h-[2.5rem] h-10 bg-green-100 hover:bg-green-200 text-green-700 transition-colors',
            className
          )}
        >
          <button
            type="button"
            onClick={openGetStartedModal}
            className="flex flex-row items-center gap-x-2 h-full px-3 font-bold w-full"
          >
            <Flag weight="bold" size="14" aria-hidden />
            Get started
          </button>
          <ConfirmationDialog.Trigger asChild>
            <button
              type="button"
              className="absolute top-1/2 -translate-y-1/2 right-1 p-2"
            >
              <X weight="bold" size="14" aria-hidden />
              <span className="sr-only">Hide the get started button</span>
            </button>
          </ConfirmationDialog.Trigger>
        </div>
      </ConfirmationDialog.Root>
    )
  }
)
