import * as React from 'react'
import { useLocalStorage } from '../../utils/use-local-storage'

type SideNavContextProps = {
  isOpen: boolean
  toggleMenu: () => void
}

const SideNavContext = React.createContext<SideNavContextProps | null>(null)

export const SideNavProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isOpen, setIsOpen] = useLocalStorage(
    'progression-side-nav-is-open',
    true
  )

  const toggleMenu = React.useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [setIsOpen])

  return (
    <SideNavContext.Provider value={{ isOpen, toggleMenu }}>
      {children}
    </SideNavContext.Provider>
  )
}

export const useSideNavContext = () => {
  const context = React.useContext(SideNavContext)

  if (!context) {
    throw new Error(
      'useSideNavContext must be used within a SideNavProvider component'
    )
  }

  return context
}
