import { ArrowSquareOut, Newspaper } from '@phosphor-icons/react'
import React from 'react'
import { Dropdown } from 'src/design-system'

export const ProductUpdatesLink: React.FC = () => {
  const path = 'https://headwayapp.co/progression-updates'
  const title = 'Product Updates'

  return (
    <Dropdown.Link
      className="text-left text-gray-900 min-w-48 flex justify-between items-center gap-1 pl-2"
      href={path}
      isExternal
    >
      <div className="headway-widget flex justify-between w-full items-center">
        <div className="flex items-center gap-2">
          <Newspaper
            className="shrink-0 h-3.5 fill-gray-500 w-5"
            weight="bold"
            aria-hidden
          />
          {title}
        </div>
        <div className="">
          <ArrowSquareOut className="text-gray-200 ml-2" />
        </div>
      </div>
    </Dropdown.Link>
  )
}
