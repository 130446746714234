import { MobileContent } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ActionsSection } from './actions-section'
import { ComparePositionSection } from './compare-position-section'
import { FocusSkillsSection } from './focus-skills-section'
import { useStore } from 'store/context'

export type GrowthSidePanelProps = {
  onClose?: () => void
}

export const GrowthSidePanel = observer((props: GrowthSidePanelProps) => {
  const { onClose } = props

  const { actions, currentUser, userSkills } = useStore()

  const fetchActions = React.useCallback(async () => {
    if (currentUser)
      await actions.fetchForUser(currentUser.id, { completed: false })
  }, [actions, currentUser])

  React.useEffect(() => {
    fetchActions()
  }, [fetchActions])

  if (!currentUser) return null

  return (
    <div className="border-0 border-gray-100 border-l border-solid h-full overflow-auto relative w-[290px] z-20">
      <div className="flex flex-col lg:fixed lg:h-screen lg:overflow-auto w-[290px]">
        <MobileContent className="px-6 pt-4 text-right">
          <button type="button" onClick={onClose}>
            <X className="h-4 text-gray-900 w-4" weight="bold" />
            <span className="sr-only">Close</span>
          </button>
        </MobileContent>
        <ComparePositionSection user={currentUser} />
        <FocusSkillsSection
          focusedUserSkills={userSkills.focusedForUser(currentUser.id)}
          suggestedUserSkills={userSkills.suggestedForUser(currentUser.id)}
          user={currentUser}
        />
        <ActionsSection
          actions={actions.incompleteForUser(currentUser.id)}
          focusSkillIds={userSkills.focusedSkillIdsForUser(currentUser.id)}
          user={currentUser}
          userSkills={userSkills.forUser(currentUser.id)}
        />
      </div>
    </div>
  )
})
