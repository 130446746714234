import * as React from 'react'
import { Plus } from '@phosphor-icons/react'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { openModal } from 'app/packs/src/utils/open-modal'
import { EVENT_KEY_NAME } from 'components/get-started-modal/shared'

export const StartFromScratch = () => (
  <button
    onClick={() => {
      trackEvent(EVENT_KEY_NAME, {
        title: 'Start from scratch',
        href: '/teams/new',
      })
      openModal('/teams/new')
    }}
    type="button"
    className="text-left flex-1 flex flex-row gap-x-4 items-center border-px border-solid border-gray-100 hover:border-gray-200 rounded-lg p-4 transition-colors"
  >
    <div className="rounded bg-blue-100 text-blue-600 p-7 flex items-center justify-center">
      <Plus aria-hidden weight="bold" size="24" />
    </div>
    <div className="flex flex-col gap-y-1">
      <h3 className="font-bold text-gray-900 text-sm">Start from scratch</h3>
      <p className="text-gray-600 text-xs mb-0">
        Create another framework — don’t worry, you won’t lose what you’ve
        already built.
      </p>
    </div>
  </button>
)
