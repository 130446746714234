import * as React from 'react'
import * as Section from '../section'
import { observer } from 'mobx-react-lite'
import { store } from 'store/index'
import { openModal } from 'app/packs/src/utils/open-modal'
import { WithAnalytics } from 'components/with-analytics'
import { EVENT_KEY_NAME } from '../shared'
import { SquaresFour, Star, User } from '@phosphor-icons/react'
import { Tag } from 'src/design-system'
import { Framework } from 'store/modules/frameworks'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export const FrameworksSection = observer(() => {
  const frameworks = store.frameworks.showInGetStarted

  return (
    <Section.Root>
      <Section.Title>Use a prebuilt Framework</Section.Title>
      <Section.Description>
        Get going faster with a tried and tested template
      </Section.Description>

      <div>
        {frameworks.map((framework) => (
          <FrameworkCard key={framework.id} framework={framework} />
        ))}
      </div>

      <WithAnalytics
        event={EVENT_KEY_NAME}
        params={{
          title: 'Explore templates',
          href: '/frameworks',
        }}
      >
        <Section.Link href="/frameworks">Explore templates</Section.Link>
      </WithAnalytics>
    </Section.Root>
  )
})

const FrameworkCard = (props: { framework: Framework }) => {
  const { framework } = props

  const onClickHandler = () => {
    const modalLink = `/view_framework_modal?framework_id=${framework.id}&source=get_started`

    trackEvent(EVENT_KEY_NAME, {
      title: 'Viewing a framework',
      data: framework.name,
      href: modalLink,
    })
    openModal(modalLink)
  }

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="flex flex-row gap-x-4 py-2 lg:my-2 text-left rounded-lg -mx-2 px-2 transition-none"
    >
      <div className="rounded bg-green-500 flex items-center justify-center relative">
        <div className="absolute -bottom-1 -right-1">
          {framework.displayOrg?.avatarUrl ? (
            <img
              src={framework.displayOrg.avatarUrl}
              alt=""
              className="w-5 h-5 rounded-full object-contain border-px border-solid border-white"
            />
          ) : (
            <div
              aria-hidden
              className="text-xs flex items-center justify-center text-white w-5 h-5 rounded-full bg-green-500 border-px border-solid border-white"
            >
              {framework.displayOrg?.name[0]}
            </div>
          )}
        </div>
        {framework.imageUrl && (
          <img
            src={framework.imageUrl}
            alt=""
            className="object-cover h-14 w-14 rounded"
          />
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        <h4 className="font-bold text-sm">{framework.name}</h4>
        <div className="flex flex-row items-center gap-x-1">
          <Tag variant="info">
            <SquaresFour
              aria-hidden
              weight="bold"
              size="12"
              className="text-blue-600 mr-1"
            />
            Framework
          </Tag>
          <Tag variant="paper">
            <User aria-hidden size="12" className="text-gray-900 mr-1" />
            {framework.positions.length}
          </Tag>
          <Tag variant="paper">
            <Star aria-hidden size="12" className="text-gray-900 mr-1" />
            {framework.skills.length}
          </Tag>
        </div>
      </div>
    </button>
  )
}
