import { Users } from '@phosphor-icons/react'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import * as SideMenu from 'components/side-menu'
import { User } from 'store/modules/users'
import { useIsActive } from 'app/packs/src/utils/use-is-active'

type ReportsLinkProps = {
  reports: User[]
  userSlug: string
}

export const ReportsLink = (props: ReportsLinkProps) => {
  const { reports, userSlug } = props

  const path = `/users/${userSlug}/reports`
  const title = 'Reports'

  return (
    <SideMenu.LinkItem
      active={useIsActive(path)}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
      href={path}
      icon={Users}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
