import { DesktopContent, MobileContent } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { Toaster } from 'react-hot-toast'
import * as React from 'react'
import { MobileSideNav } from './mobile-side-nav'
import { SideNavContent } from './side-nav-content'
import { SideNavProvider } from './side-nav-context'
import { useStore } from 'store/context'

export const SideNav = observer(() => {
  const { currentUser, positions, teams, orgs } = useStore()

  React.useEffect(() => {
    positions.fetchAll({
      include: ['team', 'disciplines', 'team.org', 'skills', 'salaries'],
      page: { size: 9999 },
    })
  }, [positions])

  React.useEffect(() => {
    orgs.fetchAll()
    teams.fetchAll({
      include: [
        'docs',
        'editors',
        'framework.disciplines',
        'framework',
        'open_positions',
        'users',
      ],
      page: { size: 9999 },
    })
  }, [teams, orgs])

  if (!currentUser) return null

  return (
    <>
      <SideNavProvider>
        <Toaster
          containerClassName="print-hide"
          toastOptions={{
            style: {
              borderRadius: '0.25rem',
              maxWidth: '500px',
            },
          }}
        />
        <nav id="side-nav">
          <DesktopContent className="print-hide">
            <SideNavContent />
          </DesktopContent>
          <MobileContent className="print-hide">
            <MobileSideNav />
          </MobileContent>
        </nav>
      </SideNavProvider>
    </>
  )
})
