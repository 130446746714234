import { ChartPie } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

type OrgInsightsLinkProps = {
  orgSlug: string
}

export const OrgInsightsLink: React.FC<OrgInsightsLinkProps> = observer(
  (props) => {
    const { orgSlug } = props

    const path = `/orgs/${orgSlug}/insights/skill-gaps`

    return (
      <SideMenu.LinkItem
        active={useIsActive(path, null, { exactMatch: false })}
        analyticsProps={sideNavAnalyticsProps({
          href: path,
          title: 'Org Insights',
        })}
        href={path}
        icon={ChartPie}
      >
        <div data-element-id="org-insights-link-title">Insights</div>
      </SideMenu.LinkItem>
    )
  }
)
