import Select, { components, DropdownIndicatorProps } from 'react-select'
import * as React from 'react'
import { CaretDown } from '@phosphor-icons/react'

type ComparePositionSelectGroupOption = {
  label: string
  options: ComparePositionSelectOption[]
}

export type ComparePositionSelectOption = { label: string; value: string }

export type ComparePositionSelectProps = {
  closeMenuOnSelect?: boolean
  disabled?: boolean
  onChange?: (positionId: string | null) => void
  options: ComparePositionSelectGroupOption[]
  placeholder?: string
  value?: string
}

export const ComparePositionSelect: React.VFC<ComparePositionSelectProps> = (
  props
) => {
  const {
    disabled,
    onChange,
    options,
    placeholder = 'Select a position',
    value,
  } = props

  const selectedOption = React.useMemo(() => {
    return options
      .flatMap((option) => option.options)
      .find((option) => {
        return option.value === value
      })
  }, [options, value])

  return (
    <div className="relative">
      <Select
        closeMenuOnSelect
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        isSearchable={false}
        value={selectedOption}
        onChange={(newValue) => {
          onChange?.((newValue as ComparePositionSelectOption)?.value)
        }}
        options={options}
        placeholder={placeholder}
        styles={{
          container: () => ({
            fontSize: 14,
            height: 34,
            width: '100%',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            border: '0 !important',
            borderColor: '#FFFFFF !important',
            boxShadow: 'none !important',
            height: 34,
            minHeight: 34,
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#CECACA !important',
            padding: 0,
          }),
          groupHeading: (baseStyles) => ({
            ...baseStyles,
            color: '#686464',
            fontSize: 12,
            fontWeight: '600',
            padding: '6px 12px 8px 12px',
            textTransform: 'capitalize',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: 34,
          }),
          indicatorSeparator: () => ({}),
          menu: (baseStyles) => ({ ...baseStyles, width: 200 }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor:
              state.isFocused || state.isSelected
                ? '#F3F2F1 !important'
                : '#FFFFFF !important',
            color: '#1B1818',
            padding: '12px 16px',
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#1B1818',
            maxWidth: 170,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            height: 34,
            input: { position: 'absolute' },
            minHeight: 34,
          }),
        }}
      />
    </div>
  )
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<ComparePositionSelectOption, false>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown aria-hidden className="h-4 text-gray-200 w-4" weight="bold" />
    </components.DropdownIndicator>
  )
}
