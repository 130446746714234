import React from 'react'
import {
  Option,
  SelectInput,
  SelectInputProps,
} from 'components/atoms/select-input'
import { useApiClient } from '../../utils/use-api-client'
import { successToast } from '../../utils/success-toast'
import { SingleValue } from 'react-select'
import { errorToast } from '../../utils/error-toast'

type SlackChannelSelectProps = {
  orgId: number
  channelOptions: Option[]
  defaultChannel: { label: string; value: string } | null
  disabled?: boolean
}

export const SlackChannelSelect: React.VFC<SlackChannelSelectProps> = (
  props
) => {
  const { orgId, channelOptions, defaultChannel, disabled } = props

  const client = useApiClient()

  const onChange: SelectInputProps['onChange'] = async (e) => {
    try {
      const event = e as SingleValue<Option>
      const channelName = event?.label
      const channelId = event?.value

      if (defaultChannel?.value === channelId) return
      const result = await client.patch<{ notice: string }>(
        `/api/v1/internal/integrations/slack`,
        {
          org_id: orgId,
          preferences: {
            channel_id: channelId,
            channel_name: channelName,
          },
        }
      )
      successToast(result.notice)
    } catch (e) {
      console.error(e)
      errorToast()
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <SelectInput
        disabled={disabled}
        defaultValue={defaultChannel}
        onChange={onChange}
        name="channel_id"
        options={channelOptions}
        placeholder="Select channel"
        closeMenuOnSelect
      />
    </div>
  )
}
