import * as React from 'react'
import { ActionCreatedNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type ActionCreatedProps = {
  notification: ActionCreatedNotification
}

export const ActionCreatedContent: React.VFC<ActionCreatedProps> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">{notification.author.fullName}</strong>{' '}
        created an action
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View on their profile</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
