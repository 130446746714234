import * as SideMenu from 'components/side-menu'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { Avatar } from 'src/design-system'
import { useIsActive } from 'app/packs/src/utils/use-is-active'

type OrgLinkProps = {
  isAdmin?: boolean
  orgSlug: string
  orgAvatarUrl: string | null
  orgName: string
}

export const OrgLink: React.FC<OrgLinkProps> = (props) => {
  const { orgSlug, orgAvatarUrl, orgName } = props

  const orgPath = `/orgs/${orgSlug}`
  const orgReadmePath = `${orgPath}/readme`
  const orgUsersPath = `${orgPath}/users`
  const orgOpenRolesPath = `${orgPath}/open_roles`

  const active = useIsActive([
    orgPath,
    orgReadmePath,
    orgUsersPath,
    orgOpenRolesPath,
  ])

  return (
    <div className="relative group mb-2">
      <SideMenu.LinkItem
        active={active}
        analyticsProps={sideNavAnalyticsProps({
          title: 'Org',
          href: orgPath,
        })}
        href={orgPath}
      >
        <Avatar
          src={orgAvatarUrl}
          fallbackText={orgName[0]}
          alt={`Avatar for ${orgName}`}
          className="border-px border-solid border-gray-100"
          size="xs"
        />
        <div className="flex items-center truncate w-full">
          <div className="truncate max-w-[calc(100%-45px)]">Organisation</div>
        </div>
      </SideMenu.LinkItem>
    </div>
  )
}
