import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Plus } from '@phosphor-icons/react'
import { useStore } from 'store/context'
import * as SideMenu from 'components/side-menu'
import { useModalContext } from 'src/design-system'
import { OrgPeopleLink } from '../org-people-link'
import { OrgSkillsLink } from '../org-skills-link'
import { Button } from 'src/design-system'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { INVITE_MODAL_ID } from 'components/invite-modal/utils'
import { InviteModalProps } from 'components/invite-modal'
import { OrgInsightsLink } from '../org-insights-link'
import { PackageProtect } from 'components/package-protect'
import { TeamsAccordion } from '../teams-accordion'
import { store } from 'store/index'

export const OrgSection = observer(() => {
  const { currentUser } = useStore()

  const { openModal } = useModalContext()

  const onClick = () => {
    trackEvent('$track_side_menu_click', { title: 'Invite' })
    openModal<InviteModalProps>(INVITE_MODAL_ID, { intent: 'side_nav' })
  }
  if (!currentUser) return null

  const { org } = currentUser
  const showPeople = currentUser.isAdmin

  const showOrgInsights =
    currentUser.isAdmin || currentUser.allReports.length > 0

  return (
    <>
      <SideMenu.MenuSectionTitle>{org?.name}</SideMenu.MenuSectionTitle>
      {org && (
        <SideMenu.MenuSection data-element-id="side-nav-build-section">
          <TeamsAccordion
            org={org}
            teams={store.teams.forOrg}
            user={currentUser}
            teamCount={3}
          />
          {showPeople && (
            <div className="relative flex items-center justify-between w-full gap-2">
              <OrgPeopleLink orgSlug={org.slug} />
              <div className="absolute top-1 right-1 bottom-1 flex items-center">
                <Button
                  className="p-0 h-full min-w-2 flex-grow-1 bg-transparent rounded flex items-center justify-center border-transparent hover:border-gray-100 border-px border-solid flex-shrink-0"
                  onClick={onClick}
                >
                  <div className="px-1">
                    <Plus className="h-3 text-gray-400 w-3" weight="bold" />
                  </div>
                </Button>
              </div>
            </div>
          )}
          <OrgSkillsLink org={org} title="Skills" />
          <PackageProtect>
            {showOrgInsights && <OrgInsightsLink orgSlug={org.slug} />}
          </PackageProtect>
        </SideMenu.MenuSection>
      )}
    </>
  )
})
