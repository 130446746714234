import { Clipboard } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

type OrgCheckinsLinkProps = {
  orgSlug: string
}

export const OrgCheckinsLink: React.FC<OrgCheckinsLinkProps> = observer(
  (props) => {
    const { orgSlug } = props

    const path = `/check-ins`

    return (
      <SideMenu.LinkItem
        active={useIsActive(path)}
        analyticsProps={sideNavAnalyticsProps({
          href: path,
          title: 'Org check-ins',
        })}
        href={path}
        icon={Clipboard}
      >
        <div data-element-id="org-checkins-link-title">Check-ins</div>
      </SideMenu.LinkItem>
    )
  }
)
