import * as React from 'react'
import { ActionCompletedNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type ActionCompletedProps = {
  notification: ActionCompletedNotification
}

export const ActionCompletedContent: React.VFC<ActionCompletedProps> = (
  props
) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">{notification.author.fullName}</strong>{' '}
        completed an action
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View on their profile</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
