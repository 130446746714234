import { WithAnalytics } from 'components/with-analytics'
import * as React from 'react'
import { EVENT_KEY_NAME } from '../shared'

export type LinkProps = {
  children: string
  href: string
  /**
   * Whether or not to open in a new tab with rel="noopener noreferrer"
   */
  isExternal?: boolean
}

export const Link = (props: LinkProps) => {
  const { children, href, isExternal } = props

  const externalProps = isExternal
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {}

  return (
    <WithAnalytics
      event={EVENT_KEY_NAME}
      params={{
        children,
        href,
      }}
    >
      <a
        href={href}
        className="text-green-700 px-1 py-1 -mx-1 rounded-lg transition-none mt-5"
        {...externalProps}
      >
        {children}
        <span aria-hidden> →</span>
      </a>
    </WithAnalytics>
  )
}
