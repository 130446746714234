import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

export const WinsLink = () => {
  const path = `/wins`
  const title = 'Wins'

  return (
    <SideMenu.LinkItem
      active={useIsActive(path, null, { exactMatch: false })}
      analyticsProps={sideNavAnalyticsProps({
        title,
        href: path,
      })}
      href={path}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
