import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Header } from './header'
import { useStore } from 'store/context'
import * as SideMenu from 'components/side-menu'
import { useSideNavContext } from './side-nav-context'
import { OnboardingChecklistDrawer } from 'components/onboarding-checklist/checklist-drawer'
import { GetStartedModal } from 'components/get-started-modal/get-started-modal'
import { GetStartedVm } from 'components/get-started-modal/get-started-vm'
import { SettingsDropUp } from './items/settings-dropup'
import { HelpSupportDropUp } from './items/help-support-dropup'
import { QuickSearch } from 'components/quick-search'
import { DefaultSection } from './items/sections/default'
import { OrgSection } from './items/sections/org'
import { LibraryDropUp } from './items/library-dropup'
import { PackageProtect } from 'components/package-protect'
import { CreateFormModalButton } from 'components/create-form-modal'
import { Plus } from '@phosphor-icons/react'

export type SideNavContentProps = {
  onClose?: () => void
}

export const SideNavContent = observer((props: SideNavContentProps) => {
  const { onClose } = props

  const { currentUser } = useStore()
  const { isOpen } = useSideNavContext()

  if (!currentUser) return null

  const getStartedVm = new GetStartedVm()

  return (
    <SideMenu.Root isOpen={isOpen}>
      <SideMenu.Body>
        <Header onClose={onClose} />
        {isOpen && (
          <>
            <div className="flex pb-4">
              <QuickSearch className="shadow-none flex-grow bg-transparent py-1.5 rounded-lg justify-start" />
              <PackageProtect>
                <CreateFormModalButton
                  title="Add"
                  colourVariant="paper"
                  user={currentUser}
                  source={'side_nav_main'}
                  className="ml-2 flex items-center flex-grow shadow-sm border-gray-200 py-1 pr-3 rounded-lg gap-2 hover:bg-white hover:shadow-lg hover:border-gray-400"
                  label={
                    <div className="flex items-center gap-1">
                      <Plus size={14} weight="bold" className="fill-gray-300" />
                      <span>Add</span>
                    </div>
                  }
                />
              </PackageProtect>
            </div>
            {!getStartedVm.showGetStarted && (
              <OnboardingChecklistDrawer triggerClassName="mb-4" />
            )}
            {getStartedVm.showGetStarted && (
              <GetStartedModal className="mb-4" />
            )}
            <DefaultSection />
            <OrgSection />
          </>
        )}
      </SideMenu.Body>
      {isOpen && (
        <SideMenu.Footer>
          <div className="flex space-between">
            <SettingsDropUp />
            <LibraryDropUp />
            <HelpSupportDropUp />
          </div>
        </SideMenu.Footer>
      )}
    </SideMenu.Root>
  )
})
