import * as React from 'react'
import { Tag, TagProps } from '../../../atoms/Tag'
import type { PropsWithoutChildren } from '../../../../types/helpers'

export type DraftTagProps = PropsWithoutChildren<Omit<TagProps, 'variant'>>

export const DraftTag: React.VFC<DraftTagProps> = (props) => (
  <Tag variant="warning" {...props}>
    DRAFT
  </Tag>
)
