import { isAfter } from 'date-fns'
import { store } from 'store/index'

export class GetStartedVm {
  readonly showDate = new Date('2023-04-25T00:00:00.000Z')

  get showGetStarted() {
    return (
      store.featureFlags.featureEnabled('get_started_modal') &&
      store.currentUser &&
      isAfter(store.currentUser.createdAt, this.showDate) &&
      store.currentUser?.orgRole === 'admin'
    )
  }
}
