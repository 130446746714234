import * as React from 'react'
import { CheckinLowActivityNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'
import pluralize from 'pluralize'

export type CheckinLowActivityProps = {
  notification: CheckinLowActivityNotification
}

export const CheckinLowActivityContent: React.VFC<CheckinLowActivityProps> = (
  props
) => {
  const { notification } = props
  const reportsCount = notification.content || ''
  pluralize.addIrregularRule("hasn't", "haven't")
  const pluralizeReports = pluralize('report', parseInt(reportsCount), true)
  const pluralizeHasnt = pluralize("hasn't", parseInt(reportsCount))

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        {pluralizeReports} {pluralizeHasnt}{' '}
        {'completed a Check-in in the last 6 months'}
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View your Reports</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
