import {
  Book,
  CaretDown,
  CaretUp,
  ListBullets,
  SquaresFour,
  Star,
} from '@phosphor-icons/react'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import { Dropdown } from 'src/design-system'
import * as SideMenu from 'components/side-menu'

export const LibraryDropUp: React.FC = () => {
  const items = [
    {
      active: useIsActive('/skills'),
      icon: Star,
      path: '/skills',
      title: 'Skills',
    },
    {
      active: useIsActive('/collections'),
      icon: ListBullets,
      path: '/collections',
      title: 'Collections',
    },
    {
      active: useIsActive('/frameworks'),
      icon: SquaresFour,
      path: '/frameworks',
      title: 'Frameworks',
    },
  ]

  const [open, setOpen] = React.useState(false)

  const onClick = React.useCallback(() => setOpen(!open), [open, setOpen])

  return (
    <Dropdown.Root open={open}>
      <Dropdown.Trigger hideCarat={true}>
        <SideMenu.ButtonItem onClick={onClick}>
          <div className="flex items-center w-full gap-2 text-gray-400">
            Library
          </div>
        </SideMenu.ButtonItem>
      </Dropdown.Trigger>
      <Dropdown.Content
        side="top"
        sideOffset={5}
        onPointerLeave={() => setOpen(false)}
      >
        {items.map((item) => (
          <Dropdown.Link
            className="text-left text-gray-900 flex items-center gap-1 pl-2"
            href={item.path}
            key={item.title}
          >
            <item.icon
              className="shrink-0 h-3.5 fill-gray-500 w-5"
              aria-hidden
            />
            {item.title}
          </Dropdown.Link>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
