import * as React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.min.css'

type TimePickerInputProps = {
  defaultValue?: Date | null
  name: string
  placeholder: string
  timeCaption: string
  timeIntervals: number
  onChange?: any
}

export const TimePickerInput: React.VFC<TimePickerInputProps> = (props) => {
  const {
    defaultValue,
    name,
    onChange,
    placeholder,
    timeCaption,
    timeIntervals,
  } = props

  return (
    <div className="relative">
      <DatePicker
        selected={defaultValue}
        dateFormat="h:mm aa"
        name={name}
        onChange={onChange}
        placeholderText={placeholder}
        showTimeSelect
        showTimeSelectOnly
        timeCaption={timeCaption}
        timeIntervals={timeIntervals}
      />
    </div>
  )
}
