import * as React from 'react'
import { EditableForm, TPosition, Tag } from 'src/design-system'
import { PositionsService } from '../../services/positions-service'

export type EditableLabelProps = {
  position: TPosition
  defaultLabels: string[]
}

export const EditableLabel: React.VFC<EditableLabelProps> = (props) => {
  const { position, defaultLabels } = props
  const [customLabel, setCustomLabel] = React.useState(position.customLabel)
  const positionsService = React.useMemo(() => new PositionsService(), [])

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomLabel(e.currentTarget.value)
  }

  const onReset = async () => {
    await positionsService.update({
      position,
      customLabel: '',
    })
    setCustomLabel('')
  }

  const submitHandler = async () => {
    await positionsService.update({
      position,
      customLabel,
    })
  }

  return (
    <EditableForm.Root onSubmit={submitHandler}>
      <EditableForm.VisualContent>
        <div className="flex flex-wrap gap-x-2 mr-1">
          {customLabel && <Tag className="text-sm">{customLabel}</Tag>}
          {!customLabel &&
            defaultLabels?.map((label) => (
              <Tag key={label} className="text-sm">
                {label}
              </Tag>
            ))}
        </div>
        <EditableForm.Trigger>
          <EditableForm.Edit />
        </EditableForm.Trigger>
      </EditableForm.VisualContent>
      <EditableForm.InsertContent>
        <EditableForm.Input placeholder="Override labels" onChange={onChange} />
        <EditableForm.Confirm />
        <EditableForm.Reset onReset={onReset} />
        <EditableForm.ErrorMessage />
      </EditableForm.InsertContent>
    </EditableForm.Root>
  )
}
