import { Drawer, MobileContent } from 'src/design-system'
import { List, SquareHalf } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { GrowthSidePanel } from 'components/growth-side-panel'
import { LogoLink } from './items/logo-link'
import {
  NotificationsInbox,
  NotificationsInboxProps,
} from 'components/notifications-inbox'
import { SideNavContent } from './side-nav-content'
import { useIsActive } from '../../utils/use-is-active'

const popoverContentProps: NotificationsInboxProps['popoverContentProps'] = {
  collisionPadding: { right: 16 },
  side: 'bottom',
}

export const MobileSideNav = observer(() => {
  return (
    <MobileContent className="bg-white border-0 border-b border-gray-100 border-solid flex fixed h-20 items-center justify-between left-0 px-4 py-6 right-0 top-0 w-full z-20">
      <Drawer
        className="w-[290px]"
        direction="left"
        title="Menu"
        overlay={false}
        trigger={
          <button
            className="flex gap-1 items-center justify-start px-2 py-1.5 w-[88px]"
            type="button"
          >
            <span className="text-gray-900 text-sm">Menu</span>
            <List className="h-4 text-gray-600 w-4" weight="bold" />
          </button>
        }
      >
        {({ onClose }) => {
          return <SideNavContent onClose={onClose} />
        }}
      </Drawer>
      <LogoLink />
      <div className="flex gap-2 items-center justify-end w-[88px]">
        {useIsActive('/') && (
          <Drawer
            className="w-[290px]"
            title="Growth Panel"
            overlay={false}
            trigger={
              <button
                className="flex items-center justify-center p-4"
                type="button"
              >
                <SquareHalf className="h-4 text-gray-600 w-4" weight="bold" />
                <span className="sr-only">Growth panel</span>
              </button>
            }
          >
            {({ onClose }) => {
              return <GrowthSidePanel onClose={onClose} />
            }}
          </Drawer>
        )}
        <NotificationsInbox
          className="mb-0.5 text-gray-600"
          popoverContentProps={popoverContentProps}
        />
      </div>
    </MobileContent>
  )
})
