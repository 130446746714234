import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

export const FeedbackLink = () => {
  const path = `/feedback`
  const title = 'Feedback'

  const isActive = useIsActive(path)

  return (
    <SideMenu.LinkItem
      active={isActive}
      href={path}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
