import * as React from 'react'

export const WinNudgeContent: React.VFC = () => {
  return (
    <div className="flex flex-col gap-y-0.5">
      <div>You haven&apos;t added a win for a week</div>
      <span className="text-theme-40">Give someone a win</span>
    </div>
  )
}
