import * as React from 'react'
import { useApiClient } from '../utils/use-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'
import { getDayList } from '../utils/date-helpers'
import { SingleValue } from 'react-select'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import {
  Option,
  SelectInput,
  SelectInputProps,
} from 'components/atoms/select-input'
import { TimePickerInput } from './atoms/timepicker/timepicker'
import { Button } from 'src/design-system'

type SlackWeeklyWinsPreferencesProps = {
  orgId: number
  weeklyWinsDay: string
  weeklyWinsTime: string
  timeZone: string
}

export const SlackWeeklyWinsPreferences: React.VFC<
  SlackWeeklyWinsPreferencesProps
> = (props) => {
  const { orgId, weeklyWinsDay, weeklyWinsTime, timeZone } = props
  const client = useApiClient()

  const [day, setDay] = React.useState<string | number | undefined>(
    weeklyWinsDay
  )
  const [time, setTime] = React.useState<Date | null>(
    weeklyWinsTime ? utcToZonedTime(new Date(weeklyWinsTime), timeZone) : null
  )

  const dayOptions = getDayList()
  const selectedDay = dayOptions.filter((item) => item.value == day)

  const onDayChanged: SelectInputProps['onChange'] = (e) => {
    const event = e as SingleValue<Option>
    setDay(event?.value)
  }

  const onTimeChanged = (newTime: Date) => {
    setTime(newTime)
  }

  const saveSettings = async () => {
    if (!time || !day) {
      errorToast('Please choose a day and time')
      return
    }

    try {
      const result = await client.patch<{ notice: string }>(
        `/api/v1/internal/integrations/slack`,
        {
          org_id: orgId,
          preferences: {
            weekly_wins_day: day,
            weekly_wins_time: zonedTimeToUtc(time, timeZone),
          },
        }
      )
      successToast(result.notice)
    } catch (e) {
      errorToast()
    }
  }

  return (
    <>
      <div className="mb-4">
        <label className="flex-1 mb-2 font-bold text-base">Which day?</label>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <SelectInput
            defaultValue={selectedDay}
            multi={false}
            name="weekly_wins_day"
            onChange={onDayChanged}
            options={dayOptions}
            placeholder="Please select a day"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="flex-1 mb-2 font-bold text-base">What time?</label>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <TimePickerInput
            defaultValue={time}
            name="weekly_wins_time"
            onChange={onTimeChanged}
            placeholder="Please select a time"
            timeCaption="Time"
            timeIntervals={60}
          />
        </div>
      </div>

      <Button colourVariant="theme" onClick={saveSettings}>
        Save settings
      </Button>
    </>
  )
}
