import * as React from 'react'
import { HouseSimple, User, SignOut } from '@phosphor-icons/react'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'src/design-system'
import { RailsActionForm } from 'components/rails-form-button/rails-form-button'
import * as SideMenu from 'components/side-menu'

export const SettingsDropUp = observer(() => {
  if (!store.currentUser?.org || !store.currentUser) return null

  const [open, setOpen] = React.useState(false)
  const profilePath = '/users/edit'
  const orgSettingsPath = `/orgs/${store.currentUser.org.slug}/edit`

  return (
    <Dropdown.Root onOpenChange={setOpen} open={open}>
      <Dropdown.Trigger hideCarat={true}>
        <SideMenu.ButtonItem>
          <div className="flex items-center w-full gap-2 text-gray-400">
            <span data-element-id="settings-menu">Settings</span>
          </div>
        </SideMenu.ButtonItem>
      </Dropdown.Trigger>
      <Dropdown.Content
        side="top"
        sideOffset={5}
        onPointerLeave={() => setOpen(false)}
      >
        <Dropdown.Link
          className="text-left text-gray-900 min-w-48 flex items-center gap-1 pl-2"
          data-turbo-action="advance"
          href={profilePath}
        >
          <User
            weight="bold"
            className="shrink-0 h-3.5 fill-gray-500 w-5"
            aria-hidden
          />
          Profile settings
        </Dropdown.Link>
        {store.currentUser.isAdmin && (
          <Dropdown.Link
            className="text-left text-gray-900 min-w-48 flex items-center gap-1 pl-2"
            data-turbo-action="advance"
            href={orgSettingsPath}
          >
            <HouseSimple
              weight="bold"
              className="shrink-0 h-3.5 fill-gray-500 w-5"
              aria-hidden
            />
            {store.currentUser.org.name} settings
          </Dropdown.Link>
        )}
        <RailsActionForm
          method="delete"
          action="/users/logout"
          formMethod="post"
        >
          <button
            type="submit"
            className="text-left text-red-700 min-w-48 flex items-center gap-1 px-2 py-1.5 hover:bg-gray-50"
          >
            <SignOut
              weight="bold"
              className="shrink-0 h-3.5 fill-red-700 w-5"
              aria-hidden
            />
            Log out
          </button>
        </RailsActionForm>
      </Dropdown.Content>
    </Dropdown.Root>
  )
})
