import * as React from 'react'
import { FormattedDateTime } from 'components/formatted-datetime'
import { WinCreatedManagerNotification } from '../../../../store/modules/notifications'

export type WinCreatedManagerContentProps = {
  notification: WinCreatedManagerNotification
}

export const WinCreatedManagerContent: React.VFC<
  WinCreatedManagerContentProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">
          {notification.author ? notification.author.fullName : 'Someone'}
        </strong>{' '}
        received a win
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View their win</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
