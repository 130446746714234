import * as SideMenu from 'components/side-menu'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { Team } from 'store/modules/teams'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import { User } from 'store/modules/users'
import { Tooltip } from 'src/design-system'
import { Asterisk } from '@phosphor-icons/react'

type TeamAccordionLinkProps = {
  team: Team
  user: User
}

export const TeamAccordionLink: React.FC<TeamAccordionLinkProps> = (props) => {
  const { team, user } = props

  const teamPath = `/teams/${team.slug}`

  const adminOrOwnTeam = user.isAdminOrEditor || team.id === user.team?.id
  const adminAndOwnTeam = user.isAdminOrEditor && team.id === user.team?.id
  return (
    <SideMenu.LinkItem
      className="w-full"
      active={useIsActive(teamPath, null, {
        exactMatch: false,
      })}
      analyticsProps={sideNavAnalyticsProps({
        href: teamPath,
        title: 'Team click',
      })}
      href={teamPath}
    >
      <Tooltip
        disabled={!adminOrOwnTeam}
        className="max-w-full"
        content={`${user.isAdminOrEditor ? team.friendlyState : ''}${
          adminAndOwnTeam ? ' · ' : ''
        }${team === user.team ? 'Your Team' : ''}`}
      >
        <div className="flex items-center px-7">
          {user.isAdminOrEditor && (
            <span
              className={`w-1 h-1 mr-1 rounded-full flex-shrink-0 bg-${
                !team.isDraft ? 'green-500' : 'gray-100'
              }`}
            ></span>
          )}
          <span className="truncate">{team.name}</span>
          {team === user.team && (
            <>
              {' '}
              <div className="-translate-y-1 -mr-1">
                <Asterisk size={8} />
              </div>
            </>
          )}
          <span className={`text-gray-300 pl-2`}>
            {!team.isTemplate && team.users.length}
          </span>
        </div>
      </Tooltip>
    </SideMenu.LinkItem>
  )
}
