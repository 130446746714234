import * as React from 'react'
import {
  useTimedContent,
  Banner,
  BannerProps,
  PropsWithoutChildren,
} from 'src/design-system'
import { trackEvent } from '../services/event-tracker'
import { WithAnalytics } from './with-analytics'
import SlackIcon from '../../images/icons/slack.svg'

export type SlackBannerProps = PropsWithoutChildren<
  Omit<BannerProps, 'onDismiss' | 'variant'>
>

export const SlackBanner: React.VFC<SlackBannerProps> = (props) => {
  const { show, hideFor } = useTimedContent('slackBannerShowAgainAfter')

  if (!show) return null

  const onDismiss = () => {
    hideFor({ days: 10 })
    trackEvent('Slack banner dismissed')
  }

  const findOutMorePath =
    'https://help.progressionapp.com/en/articles/5615534-using-progression-s-slack-app'

  return (
    <Banner variant="info" onDismiss={onDismiss} {...props}>
      <img alt="Slack" className="h-4 mr-2 w-4" src={SlackIcon} />
      Add wins faster with our newly improved Slackbot.{' '}
      <WithAnalytics event={'$track_slack_banner_find_out_more_clicked'}>
        <a href={findOutMorePath} className="text-blue-700">
          Find out more.
        </a>
      </WithAnalytics>
    </Banner>
  )
}
