import * as React from 'react'
import cn from 'classnames'
import { NotificationItemVm } from '../notification-item-vm'

export type NotificationItemAvatarProps = {
  vm: NotificationItemVm
}

export const NotificationItemAvatar: React.VFC<NotificationItemAvatarProps> = (
  props
) => {
  const { vm } = props

  if (!vm.showAvatar) return null

  const sharedClasses = 'rounded-full h-8 w-8'

  if (!vm.avatarUrl)
    return (
      <div
        className={cn(
          sharedClasses,
          'bg-theme-50 flex items-center justify-center'
        )}
      >
        <span aria-hidden>{vm.avatarInitials}</span>
        <span className="sr-only">{vm.avatarAltText}</span>
      </div>
    )

  return (
    <img
      className={cn(sharedClasses, 'p-px')}
      src={vm.avatarUrl}
      alt={vm.avatarAltText}
    />
  )
}
