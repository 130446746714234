import * as React from 'react'
import { FormattedDateTime } from 'components/formatted-datetime'
import { FeedbackRequestCreatedNotification } from '../../../../store/modules/notifications'

export type FeedbackRequestedContentProps = {
  notification: FeedbackRequestCreatedNotification
}

export const FeedbackRequestedContent: React.VFC<
  FeedbackRequestedContentProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">{notification.author.fullName}</strong>{' '}
        has requested feedback from you.
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">Give them feedback</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
