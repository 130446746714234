import * as React from 'react'
import { useApiClient } from '../utils/use-api-client'
import { errorToast } from '../utils/error-toast'
import { successToast } from '../utils/success-toast'
import { ToggleButton } from './toggle-button'

type SlackPublicWinsToggleProps = {
  orgId: number
  publicWinNotifications: boolean
}

export const SlackPublicWinsToggle: React.VFC<SlackPublicWinsToggleProps> = (
  props
) => {
  const { orgId, publicWinNotifications } = props

  const [isPublic, setIsPublic] = React.useState(publicWinNotifications)
  const client = useApiClient()

  const onChange = async () => {
    try {
      const result = await client.patch<{ notice: string }>(
        `/api/v1/internal/integrations/slack`,
        {
          org_id: orgId,
          preferences: {
            public_win_notifications: !isPublic,
          },
        }
      )
      successToast(result.notice)
      setIsPublic(!isPublic)
    } catch (e) {
      errorToast()
    }
  }
  return <ToggleButton checked={isPublic} onChange={onChange} />
}
