import {
  ChatDots,
  Checks,
  CircleWavyCheck,
  Compass,
  HandsClapping,
  Icon,
  Megaphone,
  Warning,
} from '@phosphor-icons/react'
import { NotificationType } from 'store/modules/notifications'

export const iconMap: Record<NotificationType, Icon> = {
  ActionCreatedNotification: Checks,
  ActionCompletedNotification: Checks,
  CheckinAnswersUpdatedNotification: Compass,
  CheckinAssessorReviewReminderNotification: Compass,
  CheckinFinalisedNotification: Compass,
  CheckinFinalisedManagerNotification: Compass,
  CheckinJointReviewReminderNotification: Compass,
  CheckinInitiatedManagerNotification: Compass,
  CheckinLowActivityNotification: Compass,
  CheckinStageCompleteManagerNotification: Compass,
  CheckinStageCompleteNotification: Compass,
  CommentNotification: ChatDots,
  FeedbackItemCreatedNotification: Megaphone,
  FeedbackRequestCreatedNotification: Megaphone,
  ReportsHighActivityNotification: HandsClapping,
  ReportsLowActivityNotification: Warning,
  WinCreatedManagerNotification: CircleWavyCheck,
  WinCreatedNotification: CircleWavyCheck,
  WinNudgeNotification: CircleWavyCheck,
  WinReactionNotification: CircleWavyCheck,
}
