import * as React from 'react'
import { useModalContext } from 'src/design-system'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { EVENT_KEY_NAME } from 'components/get-started-modal/shared'
import { NavigationArrow } from '@phosphor-icons/react'

export const ReviewYourFramework = () => {
  const urlIncludesTeams = window.location.pathname.includes('/teams')

  const { closeModal } = useModalContext()

  const Tag = urlIncludesTeams ? 'button' : 'a'
  const tagProps = urlIncludesTeams
    ? { type: 'button' as const }
    : {
        href: '/review-your-framework',
      }

  const onClickHandler = () => {
    trackEvent(EVENT_KEY_NAME, {
      title: 'Explore your new framework',
      href: '/review-your-framework',
    })
    if (urlIncludesTeams) closeModal()
  }

  return (
    <Tag
      {...tagProps}
      onClick={onClickHandler}
      className="flex-1 flex flex-row gap-x-4 items-center text-left border-px border-solid border-gray-100 hover:border-gray-200 rounded-lg p-4 transition-colors"
    >
      <div className="rounded bg-green-100 text-green-600 p-7 flex items-center justify-center">
        <NavigationArrow aria-hidden weight="bold" size="24" />
      </div>
      <div className="flex flex-col gap-y-1">
        <h3 className="font-bold text-gray-900 text-sm">
          Explore your new framework
        </h3>
        <p className="text-gray-600 text-xs mb-0">
          Dig a little deeper into what you’ve just created, and edit it to make
          it your own.
        </p>
      </div>
    </Tag>
  )
}
