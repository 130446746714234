import * as React from 'react'
import { CustomRadarDot } from 'components/skills-radar-chart/custom-radar-dot'
import { Position } from 'store/modules/positions'
import { SkillsRadarChartSeries } from '../../../components/skills-radar-chart/skills-radar-chart.types'
import { sortAlphaNumberValues } from 'app/packs/src/utils/sort-helpers'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { User } from 'store/modules/users'

export class ComparePositionSectionVm {
  constructor(private positions: Position[], private user: User) {}

  colourOne = '#45C639'
  colourTwo = '#39C6BA'

  get defaultPositionOneId() {
    if (this.currentPosition) return this.currentPosition.id
    if (this.options.length > 0) return this.options[0].options[0].value
  }

  emptyStateBannerText(positionOneId?: string, positionTwoId?: string) {
    if (!this.hasPositions) return "You don't have a position to compare"

    const positionOneHasNoSkills =
      positionOneId !== undefined && this.skills(positionOneId).length === 0

    const positionTwoHasNoSkills =
      positionTwoId !== undefined && this.skills(positionTwoId).length === 0

    if (positionOneHasNoSkills || positionTwoHasNoSkills) {
      return "The position doesn't have any skills to compare"
    }
  }

  get hasPositions() {
    return this.positions.length > 0
  }

  href(positionOneId?: string, positionTwoId?: string) {
    const url = new URL(`${window.location.origin}/compare`)
    if (positionOneId) url.searchParams.append('pos_1', positionOneId)
    if (positionTwoId) url.searchParams.append('pos_2', positionTwoId)
    return url.toString()
  }

  get options() {
    const options: Record<
      string,
      { code: string; label: string; value: string }[]
    > = {}

    this.sortedTeams.forEach((team) => {
      const teamPositions = this.positions.filter(
        (position) => position.team && position.team.id === team.id
      )

      teamPositions.forEach((position) => {
        position.disciplines.forEach((discipline) => {
          if (!discipline) return
          options[team.name] = options[team.name] || []

          let label = position.name
          if (this.currentPosition?.id === position.id) label += ' (You)'

          options[team.name].push({
            code: position.positionCode(discipline.initials),
            label,
            value: position.id,
          })
        })
      })
    })

    return Object.entries(options).map(([teamName, options]) => ({
      label: teamName,
      options: options
        .sort((a, b) => sortAlphaNumberValues(a.code, b.code))
        .map((option) => {
          return { label: option.label, value: option.value }
        }),
    }))
  }

  series(positionOneId?: string, positionTwoId?: string) {
    const series: SkillsRadarChartSeries = {
      positionOne: {
        colour: this.colourOne,
        dot: (chartData) => {
          return (
            <CustomRadarDot
              cx={chartData.payload.x}
              cy={chartData.payload.y}
              colour={this.colourOne}
              key={chartData.key}
            />
          )
        },
        skills: this.skills(positionOneId),
      },
    }

    if (positionTwoId) {
      series.positionTwo = {
        colour: this.colourTwo,
        skills: this.skills(positionTwoId),
      }
    }

    return series
  }

  applyPositionChange(positionId: string) {
    trackEvent('$track_growth_compare_position_clicked')
    this.fetchPositionSkills(positionId)
  }

  async fetchPositionSkills(positionId: string) {
    await store.positionSkills.fetchForPosition(positionId)
  }

  private get currentPosition() {
    return this.user.position
  }

  private get sortedTeams() {
    return this.positions
      .reduce<Team[]>((teams, position) => {
        if (
          position.team &&
          !teams.find((team) => team.id === position.team.id)
        ) {
          teams.push(position.team)
        }

        return teams
      }, [])
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  private skills(positionId?: string) {
    if (!positionId) return []

    return store.positionSkills.forPosition(positionId).map((positionSkill) => {
      return {
        id: positionSkill.skill.id,
        category: positionSkill.categoryName || 'Uncategorised',
        level: positionSkill.level,
        name: positionSkill.skill.name,
      }
    })
  }
}
