import * as React from 'react'
import { CheckinStageCompleteManagerNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type CheckinStageCompleteManagerProps = {
  notification: CheckinStageCompleteManagerNotification
}

export const CheckinStageCompleteManagerContent: React.VFC<
  CheckinStageCompleteManagerProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">
          {notification.author.fullName}&apos;s
        </strong>{' '}
        Check-in is ready for your review
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">
          Start {notification.author.fname}&apos;s Check-in
        </span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
