import * as React from 'react'
import { CheckinAssessorReviewReminderNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type CheckinAssessorReviewReminderProps = {
  notification: CheckinAssessorReviewReminderNotification
}

export const CheckinAssessorReviewReminderContent: React.VFC<
  CheckinAssessorReviewReminderProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        {"Don't forget to review"}
        {notification.author && (
          <>
            {' '}
            <strong className="font-bold">
              {notification.author.fullName}
            </strong>
          </>
        )}
        {"'s Check-in"}
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">Review Check-in</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
