import * as React from 'react'
import { FormattedDateTime } from 'components/formatted-datetime'
import { ReportsLowActivityNotification } from '../../../../store/modules/notifications'
import pluralize from 'pluralize'

export type ReportsLowActivityContentProps = {
  notification: ReportsLowActivityNotification
}

export const ReportsLowActivityContent: React.VFC<
  ReportsLowActivityContentProps
> = (props) => {
  const { notification } = props
  const reportsCount = notification.content || ''
  const pluralizeReports = pluralize('report', parseInt(reportsCount), true)

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>{pluralizeReports} added 0 updates last month</div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View your Reports</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
