import * as React from 'react'

export type FooterProps = {
  children: React.ReactNode
}

export const Footer: React.VFC<FooterProps> = (props) => {
  const { children } = props

  return (
    <footer className="flex flex-col px-3 py-4 flex-shrink-0">
      {children}
    </footer>
  )
}
