import { ArrowSquareOut, Question } from '@phosphor-icons/react'
import React from 'react'
import { Dropdown } from 'src/design-system'

export const HelpCenterLink: React.FC = () => {
  const path = 'https://help.progressionapp.com'
  const title = 'Help Center'

  return (
    <Dropdown.Link
      className="text-left text-gray-900 min-w-48 flex justify-between items-center gap-1 pl-2"
      href={path}
      isExternal
    >
      <div className="flex gap-2 items-center">
        <Question className="shrink-0 h-3.5 fill-gray-500 w-5" aria-hidden />
        {title}
      </div>
      <ArrowSquareOut className="text-gray-200" />
    </Dropdown.Link>
  )
}
