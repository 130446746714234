import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'
import { store } from 'store/index'

export const ReflectionsLink = () => {
  const path = `/reflections`
  const title = 'Reflections'

  const isActive = useIsActive(path, null, { exactMatch: false })

  if (!store.featureFlags.featureEnabled('reflections')) return null

  return (
    <SideMenu.LinkItem
      active={isActive}
      href={path}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
      data-element-id="side-nav-reflections-link"
    >
      {title}
    </SideMenu.LinkItem>
  )
}
