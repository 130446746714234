import * as React from 'react'
import cn from 'classnames'
import { CollectionCard } from 'src/design-system'
import * as Section from '../section'
import { observer } from 'mobx-react-lite'
import { store } from 'store/index'
import styles from './collections-section.module.scss'
import { openModal } from 'app/packs/src/utils/open-modal'
import { EVENT_KEY_NAME } from '../shared'
import { WithAnalytics } from 'components/with-analytics'
import { Collection } from 'store/modules/collections'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export const CollectionsSection = observer(() => {
  const collections = store.collections.showInGetStarted

  const onClickCollection = (collection: Collection) => {
    const modalLink = `/collections/${collection.id}/view_modal?source=get_started`

    trackEvent(EVENT_KEY_NAME, {
      title: 'Viewing a collection',
      data: collection.name,
      href: modalLink,
    })
    openModal(modalLink)
  }

  return (
    <Section.Root>
      <Section.Title>Discover our skill collections</Section.Title>
      <Section.Description>
        Bundles of skills hand picked for you
      </Section.Description>

      <div>
        {collections.map((collection) => (
          <button
            type="button"
            onClick={() => {
              onClickCollection(collection)
            }}
            key={collection.id}
            className="py-2 lg:my-2 rounded-lg -mx-2 px-2 text-left w-full flex"
          >
            <CollectionCard
              className={cn('hover:!bg-white p-0', styles.collectionCard)}
              collection={{
                id: parseInt(collection.id, 10),
                name: collection.name,
                authorName: collection.author?.name || '',
                skillCount: collection.skills.length,
                imageUrl: collection.imageUrl,
                authorAvatarUrl: collection.author?.avatarUrl,
                tags: collection.tags,
              }}
            />
          </button>
        ))}
      </div>

      <WithAnalytics
        event={EVENT_KEY_NAME}
        params={{
          title: 'Search skills',
          href: '/skills',
        }}
      >
        <Section.Link href="/skills">Search skills</Section.Link>
      </WithAnalytics>
    </Section.Root>
  )
})
