import * as React from 'react'
import { FormattedDateTime } from 'components/formatted-datetime'
import { CommentNotification } from '../../../../store/modules/notifications'

export type CommentContentProps = {
  notification: CommentNotification
}

export const CommentContent: React.VFC<CommentContentProps> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">
          {notification.author ? notification.author.fullName : 'Someone'}
        </strong>{' '}
        {notification.content} a comment on {notification.resourceName}
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View thread</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
