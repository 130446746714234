import { UserCircle } from '@phosphor-icons/react'
import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

type OrgPeopleLinkProps = {
  orgSlug: string
}

export const OrgPeopleLink: React.FC<OrgPeopleLinkProps> = (props) => {
  const { orgSlug } = props

  const path = `/orgs/${orgSlug}/people`
  const title = 'People'

  return (
    <SideMenu.LinkItem
      active={useIsActive(path)}
      analyticsProps={sideNavAnalyticsProps({
        title,
        href: path,
      })}
      href={path}
      icon={UserCircle}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
