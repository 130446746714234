import { Bell } from '@phosphor-icons/react'
import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as Popover from '@radix-ui/react-popover'
import cn from 'classnames'
import { AllowedNotificationTypes } from 'store/modules/notifications'
import { errorToast } from '../../utils/error-toast'
import { NotificationItem } from './notification-item/notification-item'
import { trackEvent } from '../../services/event-tracker'
import { UnreadIndicator } from './unread-indicator'
import { useStore } from 'store/context'
import * as React from 'react'
import styles from './styles.module.scss'

// TODO: delete when primary nav is removed
export type NotificationsInboxProps = {
  popoverContentProps?: Popover.PopoverContentProps
  className?: string
}

export const NotificationsInbox = observer((props: NotificationsInboxProps) => {
  const { popoverContentProps, className } = props

  const perPage = 5
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [hasUnreadNotifications, setHasUnreadNotifications] =
    React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(1)
  const { notifications } = useStore()

  const fetchNotifications = React.useCallback(
    async (number?: number) => {
      const { meta } = await notifications.fetchAll(
        {
          filter: { type: AllowedNotificationTypes },
          include: ['author'],
          page: { number, size: perPage },
        },
        { bypassCache: true }
      )

      const hasNextPage = meta?.pages && meta.pages > pageNumber
      const hasUnread = meta?.unread && (meta.unread as number) > 0
      setHasNextPage(!!hasNextPage)
      setHasUnreadNotifications(!!hasUnread)
    },
    [notifications, pageNumber, perPage]
  )

  const loadMore = async () => {
    const nextPage = pageNumber + 1
    setPageNumber(nextPage)
    fetchNotifications(nextPage)
  }

  const markAllAsRead = async () => {
    setHasUnreadNotifications(false)
    const success = await notifications.markAllAsRead()
    if (!success) {
      setHasUnreadNotifications(true)
      errorToast(
        'Something went wrong marking your notifications as read. Please try again.'
      )
    }
  }

  React.useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  return (
    <div className={cn(className)}>
      <Popover.Root>
        <Popover.Trigger
          onClick={() => trackEvent('$track_notification_bell_clicked', {})}
          className={cn('relative px-2 py-1', styles.notificationButton)}
          data-element-id="notification-bell"
        >
          <Bell weight="bold" className="w-4 h-4" />
          {hasUnreadNotifications && (
            <UnreadIndicator className="absolute right-[7px] top-1" />
          )}
        </Popover.Trigger>
        <Popover.Content
          {...popoverContentProps}
          className="bg-white rounded-md border-solid border border-gray-200 mt-4 shadow-sm max-h-[calc(80vh-4rem)] overflow-y-auto"
        >
          <div className="w-[calc(100vw-2rem)] sm:w-[400px] py-2 mb-3">
            <div className="flex flex-row items-center px-6 pt-4 pb-2">
              <div className="flex-1 bold text-base">Notifications</div>
              {hasUnreadNotifications && (
                <div className="flex-0">
                  <button className="text-theme-40" onClick={markAllAsRead}>
                    Mark all as read
                  </button>
                </div>
              )}
            </div>
            {notifications.all.length === 0 && (
              <div className="px-6 py-4 text-center text-gray-400">
                You don&apos;t have any notifications
              </div>
            )}
            {notifications.allSorted.map((notification, index) => (
              <NotificationItem
                notification={notification}
                showBottomBorder={
                  index !== notifications.all.length - 1 || hasNextPage
                }
                key={`notification-${notification.id}`}
              />
            ))}
          </div>
          {hasNextPage && (
            <div className="flex flex-row items-center justify-center pb-5">
              <Button
                variant="outline"
                colourVariant="theme"
                size="default"
                onClick={loadMore}
              >
                Load more
              </Button>
            </div>
          )}
        </Popover.Content>
      </Popover.Root>
    </div>
  )
})
