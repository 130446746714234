import * as React from 'react'
import { FormattedDateTime } from 'components/formatted-datetime'
import { WinReactionNotification } from '../../../../store/modules/notifications'

export type WinReactionContentProps = {
  notification: WinReactionNotification
}

export const WinReactionContent: React.VFC<WinReactionContentProps> = (
  props
) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        <strong className="font-bold">{notification.author.fullName}</strong>{' '}
        reacted {notification.content} to your win
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View win</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
