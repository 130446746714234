import * as React from 'react'
import { ActionCompletedContent } from './action-completed-content'
import { ActionCreatedContent } from './action-created-content'
import { CheckinAnswersUpdatedContent } from './checkin-answers-updated-content'
import { CheckinAssessorReviewReminderContent } from './checkin-assessor-review-reminder-content'
import { CheckinFinalisedContent } from './checkin-finalised-content'
import { CheckinFinalisedManagerContent } from './checkin-finalised-manager-content'
import { CheckinInitiatedManagerContent } from './checkin-initiated-manager-content'
import { CheckinJointReviewReminderContent } from './checkin-joint-review-reminder-content'
import { CheckinLowActivityContent } from './checkin-low-activity-content'
import { CheckinStageCompleteContent } from './checkin-stage-complete-content'
import { CheckinStageCompleteManagerContent } from './checkin-stage-complete-manager-content'
import { CommentContent } from './comment-content'
import { FeedbackReceivedContent } from './feedback-received-content'
import { FeedbackRequestedContent } from './feedback-requested-content'
import { Notification } from '../../../../store/modules/notifications'
import { ReportsHighActivityContent } from './reports-high-activity-content'
import { ReportsLowActivityContent } from './reports-low-activity-content'
import { WinCreatedContent } from './win-created-content'
import { WinCreatedManagerContent } from './win-created-manager-content'
import { WinNudgeContent } from './win-nudge-content'
import { WinReactionContent } from './win-reaction-content'

export const contentMap = (notification: Notification) => {
  switch (notification.notificationType) {
    case 'ActionCreatedNotification':
      return <ActionCreatedContent notification={notification} />
    case 'ActionCompletedNotification':
      return <ActionCompletedContent notification={notification} />
    case 'CheckinAnswersUpdatedNotification':
      return <CheckinAnswersUpdatedContent notification={notification} />
    case 'CheckinFinalisedNotification':
      return <CheckinFinalisedContent notification={notification} />
    case 'CheckinFinalisedManagerNotification':
      return <CheckinFinalisedManagerContent notification={notification} />
    case 'CheckinInitiatedManagerNotification':
      return <CheckinInitiatedManagerContent notification={notification} />
    case 'CheckinStageCompleteManagerNotification':
      return <CheckinStageCompleteManagerContent notification={notification} />
    case 'CheckinStageCompleteNotification':
      return <CheckinStageCompleteContent notification={notification} />
    case 'CommentNotification':
      return <CommentContent notification={notification} />
    case 'CheckinAssessorReviewReminderNotification':
      return (
        <CheckinAssessorReviewReminderContent notification={notification} />
      )
    case 'CheckinJointReviewReminderNotification':
      return <CheckinJointReviewReminderContent notification={notification} />
    case 'CheckinLowActivityNotification':
      return <CheckinLowActivityContent notification={notification} />
    case 'WinCreatedNotification':
      return <WinCreatedContent notification={notification} />
    case 'WinCreatedManagerNotification':
      return <WinCreatedManagerContent notification={notification} />
    case 'WinNudgeNotification':
      return <WinNudgeContent />
    case 'WinReactionNotification':
      return <WinReactionContent notification={notification} />
    case 'FeedbackItemCreatedNotification':
      return <FeedbackReceivedContent notification={notification} />
    case 'FeedbackRequestCreatedNotification':
      return <FeedbackRequestedContent notification={notification} />
    case 'ReportsHighActivityNotification':
      return <ReportsHighActivityContent notification={notification} />
    case 'ReportsLowActivityNotification':
      return <ReportsLowActivityContent notification={notification} />
  }
}
