import { DesktopContent, MobileContent } from 'src/design-system'
import * as React from 'react'
import { LogoLink } from './items/logo-link'
import {
  NotificationsInbox,
  NotificationsInboxProps,
} from 'components/notifications-inbox'
import { CaretDoubleLeft, X } from '@phosphor-icons/react'
import { useSideNavContext } from './side-nav-context'
import { AnimatePresence, motion } from 'framer-motion'
import { trackEvent } from '../../services/event-tracker'
import { PackageProtect } from 'components/package-protect'

const popoverContentProps: NotificationsInboxProps['popoverContentProps'] = {
  side: 'right',
  sideOffset: 10,
}

export type HeaderProps = {
  onClose?: () => void
}

export const Header: React.VFC<HeaderProps> = (props) => {
  const { onClose } = props

  const { toggleMenu, isOpen } = useSideNavContext()

  return (
    <div className="mb-4 flex items-center justify-between relative">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LogoLink />
          </motion.div>
        )}
      </AnimatePresence>

      <DesktopContent>
        <div className="flex flex-col gap-y-4">
          <div
            className="flex flex-row items-center gap-x-1"
            style={{ minHeight: '30px' }}
          >
            <motion.button
              variants={{
                open: {
                  rotateY: 0,
                  transition: { duration: 0.15 },
                },
                closed: {
                  rotateY: 180,
                  transition: { duration: 0.15 },
                },
              }}
              initial={isOpen ? 'open' : 'closed'}
              animate={isOpen ? 'open' : 'closed'}
              type="button"
              onClick={() => {
                toggleMenu()
                trackEvent('$side_nav_toggle_collapsible_menu', {
                  isOpen: !isOpen,
                })
              }}
              title="Toggle collapsible menu"
              className="outline-none px-2 py-1 ml-auto"
            >
              <CaretDoubleLeft
                weight="bold"
                className="w-4 h-4 text-gray-200"
              />
            </motion.button>

            <PackageProtect>
              <motion.div
                initial={{ opacity: isOpen ? 1 : 0 }}
                animate={{ opacity: isOpen ? 1 : 0 }}
              >
                <NotificationsInbox popoverContentProps={popoverContentProps} />
              </motion.div>
            </PackageProtect>
          </div>
        </div>
      </DesktopContent>
      <MobileContent>
        <button className="mr-1.5" onClick={onClose} type="button">
          <X className="h-4 text-gray-900 w-4" weight="bold" />
          <span className="sr-only">Close</span>
        </button>
      </MobileContent>
    </div>
  )
}
