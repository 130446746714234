import * as React from 'react'
import { IconButton, IconButtonProps } from '../../atoms/IconButton'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { EditOutline } from '@easy-eva-icons/react'

export type EditProps = PropsWithoutChildren<IconButtonProps>

export const Edit: React.VFC<EditProps> = (props) => {
  const { title = 'Edit', className, ...restProps } = props

  return (
    <IconButton title={title} hideBorder {...restProps}>
      <EditOutline />
    </IconButton>
  )
}
