import { Star } from '@phosphor-icons/react'
import React from 'react'
import { Org } from 'store/modules/orgs'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'

type OrgSkillsLinkProps = {
  className?: string
  org: Org
  title: string
}

export const OrgSkillsLink: React.FC<OrgSkillsLinkProps> = (props) => {
  const { className, org, title } = props

  const path = org.skillsPath

  return (
    <SideMenu.LinkItem
      active={useIsActive(path)}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
      className={className}
      href={path}
      icon={Star}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
