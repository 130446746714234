import { ToggleLeft, ToggleRight } from '@phosphor-icons/react'
import * as React from 'react'

type FormToggleProps = {
  name: string
  value: boolean
}

export const FormToggle: React.VFC<FormToggleProps> = (props) => {
  const { name, value } = props

  const [checked, setChecked] = React.useState(value)

  const onChange = () => {
    setChecked(!checked)
  }

  const FilterIcon = checked ? ToggleRight : ToggleLeft

  return (
    <label className="text-sm text-gray-900 flex flex-row items-center gap-x-1 mb-0 select-none whitespace-nowrap">
      <input name={name} type="hidden" value="false" />
      <input
        checked={checked}
        className="sr-only"
        name={name}
        onChange={onChange}
        type="checkbox"
        value="true"
      />
      <FilterIcon
        aria-hidden
        size={28}
        className="text-theme-50 mt-0.5"
        weight={checked ? 'fill' : 'bold'}
      />
    </label>
  )
}
