import {
  ArrowLineLeft,
  ArrowLineRight,
  ArrowUpRight,
  Buildings,
  ChatsCircle,
  CheckCircle,
  Checks,
  CircleWavyCheck,
  Clipboard,
  Compass,
  Copy,
  GearSix,
  Globe,
  Handshake,
  IdentificationBadge,
  Info,
  Lightbulb,
  MagicWand,
  Megaphone,
  MinusCircle,
  PencilSimple,
  PencilSimpleLine,
  Plus,
  Question,
  SquaresFour,
  Star,
  Trash,
  TrendUp,
  User,
  UserCircle,
  Users,
  UsersThree,
  Warning,
} from '@phosphor-icons/react'
import {
  AvatarButtonGroup,
  Banner,
  DraftTag,
  Link,
  Notice,
  Tag,
  UpgradeTag,
} from 'src/design-system'
import { CheckboxIcon } from 'components/checkbox-icon'
import { CopyUrlLink } from 'components/copy-url-link'
import { CreateForm } from 'components/create-form'
import { EditableLabel } from 'components/positions/editable-label'
import { FormToggle } from 'components/form-toggle'
import { forRails } from 'components/for-rails'
import { FreemiumBanner } from 'components/freemium-banner'
import { InviteModalWrapper } from 'components/invite-modal/invite-modal-wrapper'
import { NotificationsInbox } from 'components/notifications-inbox'
import { OrgColorFormField } from 'components/org-color-form-field'
import { OrgPublicToggle } from 'components/public-org-toggle'
import { registerComponents } from '../src/utils/react/registry'
import { SideNav } from 'components/side-nav'
import { SlackBanner } from 'components/slack-banner'
import { SlackChannelSelect } from 'components/slack-channel-select'
import { SlackPreferenceToggle } from 'components/slack-preference-toggle'
import { SlackPublicWinsToggle } from 'components/slack-public-wins-toggle'
import { SlackWeeklyWinsPreferences } from 'components/slack-weekly-wins-preferences'
import { SubscriptionBanner } from 'components/subscription-banner/subscription-banner'
import { SkillModalButtonWithProvider } from 'components/skill-modal/skill-modal-button'

registerComponents({
  ArrowLineLeftIcon: forRails(ArrowLineLeft),
  ArrowLineRightIcon: forRails(ArrowLineRight),
  ArrowUpRightIcon: forRails(ArrowUpRight),
  AvatarButtonGroup: forRails(AvatarButtonGroup),
  Banner: forRails(Banner),
  BuildingsIcon: forRails(Buildings),
  ChatsCircleIcon: forRails(ChatsCircle),
  CheckCircleIcon: forRails(CheckCircle),
  CheckboxIcon: forRails(CheckboxIcon),
  ChecksIcon: forRails(Checks),
  CircleWavyCheckIcon: forRails(CircleWavyCheck),
  ClipboardIcon: forRails(Clipboard),
  CompassIcon: forRails(Compass),
  CopyIcon: forRails(Copy),
  CopyUrlLink: forRails(CopyUrlLink),
  CreateForm: forRails(CreateForm),
  DraftTag: forRails(DraftTag),
  EditableLabel: forRails(EditableLabel),
  FormToggle: forRails(FormToggle),
  FreemiumBanner: forRails(FreemiumBanner),
  GearSixIcon: forRails(GearSix),
  GlobeIcon: forRails(Globe),
  HandshakeIcon: forRails(Handshake),
  IdentificationBadgeIcon: forRails(IdentificationBadge),
  InfoIcon: forRails(Info),
  InviteModalWrapper: forRails(InviteModalWrapper),
  LightbulbIcon: forRails(Lightbulb),
  Link: forRails(Link),
  MagicWandIcon: forRails(MagicWand),
  MegaphoneIcon: forRails(Megaphone),
  MinusCircleIcon: forRails(MinusCircle),
  Notice: forRails(Notice),
  NotificationsInbox: forRails(NotificationsInbox),
  OrgColorFormField: forRails(OrgColorFormField),
  OrgPublicToggle: forRails(OrgPublicToggle),
  PencilSimpleIcon: forRails(PencilSimple),
  PencilSimpleLineIcon: forRails(PencilSimpleLine),
  PlusIcon: forRails(Plus),
  QuestionIcon: forRails(Question),
  SideNav: forRails(SideNav),
  SkillModalButtonWithProvider: forRails(SkillModalButtonWithProvider),
  SlackBanner: forRails(SlackBanner),
  SlackChannelSelect: forRails(SlackChannelSelect),
  SlackPreferenceToggle: forRails(SlackPreferenceToggle),
  SlackPublicWinsToggle: forRails(SlackPublicWinsToggle),
  SlackWeeklyWinsPreferences: forRails(SlackWeeklyWinsPreferences),
  SquaresFourIcon: forRails(SquaresFour),
  StarIcon: forRails(Star),
  Tag: forRails(Tag),
  TrashIcon: forRails(Trash),
  TrendUpIcon: forRails(TrendUp),
  SubscriptionBanner: forRails(SubscriptionBanner),
  UpgradeTag: forRails(UpgradeTag),
  UserCircleIcon: forRails(UserCircle),
  UserIcon: forRails(User),
  UsersIcon: forRails(Users),
  UsersThreeIcon: forRails(UsersThree),
  WarningIcon: forRails(Warning),
})
