import * as React from 'react'
import cn from 'classnames'
import styles from './EditableForm.module.scss'
import { IconButton, IconButtonProps } from '../../atoms/IconButton'
import type { PropsWithoutChildren } from '../../../types/helpers'
import { EditableFormContext } from './EditableFormContext'
import { ArrowCounterClockwise } from '@phosphor-icons/react'

export type ResetProps = {
  onReset: () => void
} & Omit<PropsWithoutChildren<IconButtonProps>, 'onClick' | 'type'>

export const Reset: React.VFC<ResetProps> = (props) => {
  const { title = 'Reset', className, onReset, ...restProps } = props

  const { reset } = React.useContext(EditableFormContext)

  return (
    <IconButton
      type="button"
      title={title}
      hideBorder
      className={cn(styles.icon, className)}
      onClick={(e) => {
        e.stopPropagation()
        reset()
        onReset()
      }}
      {...restProps}
    >
      <ArrowCounterClockwise />
    </IconButton>
  )
}
