import { Notification } from '../../../store/modules/notifications'

export class NotificationItemVm {
  constructor(private notification: Notification) {}

  avatarInitials = this.notification.author?.initials
  avatarUrl = this.notification.author?.avatarUrl
  notificationType = this.notification.notificationType

  get showUnreadIndicator() {
    return !this.notification.read
  }

  get showAvatar() {
    return this.notification.author !== null
  }

  get avatarAltText() {
    if (!this.notification.author) return

    return `Avatar for ${this.notification.author.fullName}`
  }
}
