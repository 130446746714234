import { useModalContext } from 'src/design-system'
import React, {
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
  VFC,
} from 'react'
import { closeModal } from '../../utils/close-modal'
import { InviteModalProps } from './invite-modal'
import { INVITE_MODAL_ID } from './utils'

export type InviteModalWrapperProps = {
  children: ReactNode
  intent: string
  positionId?: string
}

const wrapElement = (
  child: ReactNode,
  handler: (e?: React.MouseEvent) => void
): ReactNode => {
  if (Array.isArray(child)) return child.map((c) => wrapElement(c, handler))
  if (!isValidElement(child)) return child

  return cloneElement(child, { onClick: handler } as React.Attributes)
}

export const InviteModalWrapper: VFC<InviteModalWrapperProps> = ({
  intent,
  children,
  positionId,
}) => {
  return (
    <BaseInviteModalWrapper intent={intent} positionId={positionId}>
      {children}
    </BaseInviteModalWrapper>
  )
}

export const BaseInviteModalWrapper: VFC<InviteModalWrapperProps> = ({
  children,
  intent,
  positionId,
}) => {
  const { openModal } = useModalContext()

  const handleClick = useCallback(() => {
    // rails modals conflict with this react modal, so we need
    // to close any rails modals that might be open
    closeModal()

    openModal<InviteModalProps>(INVITE_MODAL_ID, { intent, positionId })
  }, [openModal, intent, positionId])

  const wrapped = useMemo(
    () => wrapElement(children, handleClick),
    [children, handleClick]
  )

  return <>{wrapped}</>
}
