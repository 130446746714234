import * as React from 'react'
import { Tag } from 'src/design-system'
import { Icon, Note, Star, User } from '@phosphor-icons/react'
import megAvatar from 'app/packs/images/meg-avatar.png'
import pluralize from 'pluralize'
import { WithAnalytics } from 'components/with-analytics'
import { EVENT_KEY_NAME } from '../shared'
import * as Section from '../section'

type Item = {
  href: string
  icon: Icon
  title: string
  articlesCount: number
}

const items: Item[] = [
  {
    href: 'http://help.progressionapp.com/en/articles/7121019-admins-build-training-module',
    icon: Note,
    title: 'Getting started',
    articlesCount: 1,
  },
  {
    href: 'http://help.progressionapp.com/en/collections/3902018-positions',
    icon: User,
    title: 'Positions',
    articlesCount: 3,
  },
  {
    href: 'http://help.progressionapp.com/en/collections/3902019-skills',
    icon: Star,
    title: 'Skills',
    articlesCount: 10,
  },
]

export const HelpSection = () => {
  return (
    <Section.Root>
      <Section.Title>Need some help?</Section.Title>
      <Section.Description>
        Find an answer in our Help Centre
      </Section.Description>

      <div>
        {items.map((item) => (
          <Item key={item.title} {...item} />
        ))}
      </div>

      <WithAnalytics
        event={EVENT_KEY_NAME}
        params={{
          title: 'Visit the Help Centre',
          href: 'https://help.progressionapp.com/en/',
        }}
      >
        <Section.Link href="https://help.progressionapp.com/en/" isExternal>
          Visit the Help Centre
        </Section.Link>
      </WithAnalytics>
    </Section.Root>
  )
}

const Item = (props: Item) => {
  const { href, icon: Icon, title, articlesCount } = props

  return (
    <WithAnalytics
      event={EVENT_KEY_NAME}
      params={{
        title,
        href,
      }}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        className="flex flex-row gap-x-4 py-2 lg:my-2 rounded-lg -mx-2 px-2 transition-none"
      >
        <div className="rounded bg-green-100 p-5 flex items-center justify-center relative">
          <img
            src={megAvatar}
            alt=""
            className="absolute -bottom-1 -right-1 w-5 h-5"
          />
          <Icon
            aria-hidden
            weight="bold"
            size="16"
            className="text-green-600"
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <h4 className="font-bold text-sm">{title}</h4>
          <Tag variant="paper">
            <Note aria-hidden size="12" className="text-gray-900 mr-1" />
            {articlesCount} {pluralize('article', articlesCount)}
          </Tag>
        </div>
      </a>
    </WithAnalytics>
  )
}
