import { ColorPicker, toColor, useColor } from 'react-color-palette'
import * as React from 'react'
import cn from 'classnames'

type OrgColorFormFieldProps = {
  description: string
  error: string | null
  fallbackColor: string
  initialColor: string | null
  name: string
}

export const OrgColorFormField: React.VFC<OrgColorFormFieldProps> = (props) => {
  const { description, error, fallbackColor, initialColor, name } = props

  const [showColorPicker, setShowColorPicker] = React.useState(false)
  const colorPickerRef = React.useRef<HTMLDivElement | null>(null)
  const colorPickerTriggerRef = React.useRef<HTMLButtonElement | null>(null)

  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const initialInputValue = initialColor || ''
  const [inputValue, setInputValue] = React.useState(initialInputValue)

  const hexColor = initialColor && !error ? initialColor : fallbackColor
  const [selectedHexColor, setSelectedHexColor] = React.useState(hexColor)
  const [color, setColor] = useColor('hex', hexColor)

  React.useEffect(() => {
    const handleClickOutside = (e: FocusEvent) => {
      const clickedColorPicker = colorPickerRef.current?.contains(
        e.target as Node
      )

      const clickedColorPickerTrigger = colorPickerTriggerRef.current?.contains(
        e.target as Node
      )

      const clickedInput = inputRef.current?.contains(e.target as Node)

      if (!clickedColorPicker && !clickedColorPickerTrigger && !clickedInput)
        setShowColorPicker(false)
    }

    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const onInputChange = (value: string) => {
    if (value.startsWith('#') && value.length === 7) {
      onColorPickerChangeComplete(value)
      setColor(toColor('hex', value))
    } else if (!value.startsWith('#') && value.length === 6) {
      onColorPickerChangeComplete(`#${value}`)
      setColor(toColor('hex', `#${value}`))
    } else {
      setInputValue(value)
    }
  }

  const onColorPickerChangeComplete = (hexColor: string) => {
    setInputValue(hexColor)
    setSelectedHexColor(hexColor)
    if (name === 'header_color') setHeaderBackgroundColor(hexColor)
  }

  const setHeaderBackgroundColor = (hexColor: string) => {
    const element = document.getElementById(
      'header-container'
    ) as HTMLInputElement

    if (element) element.setAttribute('style', `background-color: ${hexColor}`)
  }

  return (
    <div className="relative">
      <div
        className={cn(
          'form-group',
          error ? 'form-group-invalid' : 'form-group-valid'
        )}
      >
        <input
          className={cn(
            'form-control pl-10',
            error ? 'is-invalid' : 'is-valid'
          )}
          id={`org_${name}`}
          name={`org[${name}]`}
          onChange={(e) => onInputChange(e.target.value)}
          onClick={() => setShowColorPicker(true)}
          placeholder="E.g. ff0000"
          ref={inputRef}
          type="text"
          value={inputValue}
        />
        {error && <div className="invalid-feedback">{error}</div>}
        <small className="form-text text-gray-600">{description}</small>
      </div>
      <button
        className="color-picker-trigger"
        onClick={() => setShowColorPicker(!showColorPicker)}
        ref={colorPickerTriggerRef}
        style={{ backgroundColor: selectedHexColor }}
        type="button"
      ></button>
      {showColorPicker && (
        <div className="color-picker-container" ref={colorPickerRef}>
          <ColorPicker
            color={color}
            onChange={setColor}
            onChangeComplete={(color) => onColorPickerChangeComplete(color.hex)}
            height={192}
            hideHEX
            hideHSV
            hideRGB
            width={214}
          />
        </div>
      )}
    </div>
  )
}
