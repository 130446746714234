import * as React from 'react'
import { CheckinFinalisedManagerNotification } from '../../../../store/modules/notifications'
import { FormattedDateTime } from 'components/formatted-datetime'

export type CheckinFinalisedManagerContentProps = {
  notification: CheckinFinalisedManagerNotification
}

export const CheckinFinalisedManagerContent: React.VFC<
  CheckinFinalisedManagerContentProps
> = (props) => {
  const { notification } = props

  return (
    <div className="flex flex-col gap-y-0.5">
      <div>
        {notification.author && (
          <strong className="font-bold">{notification.author.fullName}</strong>
        )}
        {' completed a Check-in with '}
        {notification.content && (
          <strong className="font-bold">{notification.content}</strong>
        )}
      </div>
      <div className="flex flex-row items-center justify-between gap-2 flex-wrap">
        <span className="text-theme-40">View Check-in Summary</span>
        <FormattedDateTime
          className="text-gray-600 text-xs"
          date={notification.createdAt}
        />
      </div>
    </div>
  )
}
