import React from 'react'
import { sideNavAnalyticsProps } from '../side-nav-analytics-props'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import * as SideMenu from 'components/side-menu'
import { useStore } from 'store/context'

export const ActionsLink = () => {
  const { currentUser } = useStore()

  const path = `/users/${currentUser?.slug}/actions`
  const title = 'Actions'

  const isActive = useIsActive(path)

  return (
    <SideMenu.LinkItem
      active={isActive}
      href={path}
      analyticsProps={sideNavAnalyticsProps({
        href: path,
        title,
      })}
    >
      {title}
    </SideMenu.LinkItem>
  )
}
