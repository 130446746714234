import { Dropdown } from 'src/design-system'
import React from 'react'
import * as SideMenu from 'components/side-menu'
import { PackageProtect } from 'components/package-protect'
import { ProductFeedbackLink } from './product-feedback-link'
import { ProductUpdatesLink } from './product-updates-link'
import { HelpCenterLink } from './help-center-link'
import { useStore } from 'store/context'

export const HelpSupportDropUp = () => {
  const { currentUser } = useStore()

  const [open, setOpen] = React.useState(false)

  const onClick = React.useCallback(() => setOpen(!open), [open, setOpen])

  return (
    <Dropdown.Root open={open}>
      <Dropdown.Trigger hideCarat={true}>
        <SideMenu.ButtonItem onClick={onClick}>
          <div className="flex items-center w-full gap-2 text-gray-400">
            <span data-element-id="help-menu">Help</span>
          </div>
        </SideMenu.ButtonItem>
      </Dropdown.Trigger>
      <Dropdown.Content
        side="top"
        sideOffset={5}
        onPointerLeave={() => setOpen(false)}
      >
        <HelpCenterLink />
        {currentUser && (
          <ProductFeedbackLink
            fullName={currentUser.fullName}
            email={currentUser.email}
          />
        )}
        <PackageProtect>
          <ProductUpdatesLink />
        </PackageProtect>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
