import { GlobalModal } from 'src/design-system'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import * as MegaModal from 'components/mega-modal'
import {
  CollectionsSection,
  FrameworksSection,
  HelpSection,
  ReviewYourFramework,
  StartFromScratch,
  BookADemo,
} from './sections'
import { GetStartedModalButton } from './get-started-modal-button'
import { removeUrlParams } from '../../utils/url-helpers'

type GetStartedModalProps = {
  defaultOpen?: boolean
  className?: string
}

export const GetStartedModal = (props: GetStartedModalProps) => {
  const { defaultOpen = false, className } = props
  const [open, setOpen] = React.useState(defaultOpen)

  React.useEffect(() => {
    const openGetStartedModalParam = 'open_get_started'
    const showGetStartedQueryParamPresent =
      Boolean(
        new URLSearchParams(window.location.search).get(
          openGetStartedModalParam
        )
      ) || undefined

    if (showGetStartedQueryParamPresent) setOpen(true)
    removeUrlParams(openGetStartedModalParam)
  }, [])

  return (
    <>
      <MegaModal.Root
        title="Get started"
        className="!max-w-[1170px] h-auto"
        open={open}
        onOpenChange={setOpen}
      >
        <MegaModal.Nav>
          <div className="flex flex-row justify-between items-center w-full">
            Get started
            <GlobalModal.CloseButton className="flex items-center rounded-sm !outline-none focus:border focus:border-solid focus:!border-theme-60">
              <X weight="bold" className="text-gray-900 w-4 h-4" />
              <span className="sr-only">Close</span>
            </GlobalModal.CloseButton>
          </div>
        </MegaModal.Nav>
        <MegaModal.Body className="p-10" disableRightPane={true}>
          <header className="mb-10">
            <h2 className="font-bold text-gray-900 text-3xl mb-4">
              Welcome to Progression – it’s great to have you here.
            </h2>
            <p className="text-base text-gray-600">
              You’ve already made a good start on your first framework, but
              don&apos;t stop there.
            </p>
          </header>
          <div className="flex flex-col gap-y-10">
            <div className="flex flex-col lg:flex-row gap-y-4 gap-x-10">
              <ReviewYourFramework />
              <StartFromScratch />
              <BookADemo />
            </div>
            <hr className="bg-gray-100 w-full m-0 border-0 h-px" />
            <div className="flex flex-col lg:flex-row gap-y-12 gap-x-10">
              <CollectionsSection />
              <FrameworksSection />
              <HelpSection />
            </div>
          </div>
        </MegaModal.Body>
      </MegaModal.Root>
      <GetStartedModalButton onOpenChange={setOpen} className={className} />
    </>
  )
}
