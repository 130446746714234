import { CaretDown, CaretUp, Plus, UsersThree } from '@phosphor-icons/react'
import * as Collapsible from '@radix-ui/react-collapsible'
import React from 'react'
import { Org } from 'store/modules/orgs'
import { Team } from 'store/modules/teams'
import { User } from 'store/modules/users'
import * as SideMenu from 'components/side-menu'
import { Button, Loader } from 'src/design-system'
import { useOpenNewTeamModal } from 'components/new-team-modal/utils'
import { useIsActive } from 'app/packs/src/utils/use-is-active'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { TeamsAccordionContent } from './teams-accordion-content'
import cn from 'classnames'

type TeamsAccordionProps = {
  org: Org
  teamCount?: number
  teams: Team[]
  user: User
}

export const TeamsAccordion: React.FC<TeamsAccordionProps> = observer(
  (props) => {
    const { org, teamCount, teams, user } = props

    const localStorageKey = 'teams-accordion-open'

    const [open, setOpen] = React.useState<boolean>(
      localStorage.getItem(localStorageKey) !== 'false'
    )

    const onClick = React.useCallback(() => {
      const newMode = !open
      setOpen(newMode)
      localStorage.setItem(localStorageKey, `${newMode}`)
    }, [open])

    const visibleTeams = store.teams.viewableForCurrentUser

    const canCreateTeam = user.isAdmin

    const sortedTeams = React.useMemo(() => {
      return visibleTeams.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
    }, [visibleTeams])

    const { openNewTeamModal } = useOpenNewTeamModal()

    const openCreateTeam = React.useCallback(() => {
      openNewTeamModal()
    }, [openNewTeamModal])

    const CaretIcon = open ? CaretUp : CaretDown
    const teamsPath = '/teams'

    if (!user) return null

    return (
      <div className="relative">
        <Collapsible.Root
          open={open}
          onOpenChange={(open) => setOpen(!!open)}
          className="flex-grow-1"
        >
          <div className="flex gap-2">
            <SideMenu.LinkItem
              icon={UsersThree}
              className="pr-8"
              href="/teams"
              active={useIsActive(teamsPath, null, {
                exactMatch: true,
              })}
            >
              <div className="flex items-stretch justify-between w-full">
                <span
                  data-element-id="teams-side-nav-title"
                  className="inline-flex items-center"
                  data-testid="teams-side-nav-title"
                >
                  Teams{' '}
                  <span className="text-gray-300 pl-2">
                    {store.teams.loading ? (
                      <Loader className="w-3 h-3" variant="paper" />
                    ) : (
                      teams.length
                    )}
                  </span>
                </span>{' '}
              </div>
            </SideMenu.LinkItem>
            {teams && teams.length > 0 && (
              <Collapsible.Trigger
                onClick={onClick}
                data-testid="teams-accordion-trigger"
                className={cn(
                  'absolute top-1 h-6 w-6 flex-grow-1 bg-transparent rounded flex items-center justify-center border-transparent hover:border-gray-100 border-px border-solid flex-shrink-0',
                  !canCreateTeam && 'right-1',
                  canCreateTeam && 'right-7'
                )}
              >
                <div className="px-1">
                  <CaretIcon className="h-3 text-gray-400 w-3" weight="bold" />
                </div>
              </Collapsible.Trigger>
            )}
          </div>
          <Collapsible.Content className="relative">
            <TeamsAccordionContent
              teams={sortedTeams}
              user={user}
              org={org}
              teamCount={teamCount}
            />
            <div className="absolute left-[18px] top-0 bottom-0 border-0 border-gray-100 border-l border-solid"></div>
          </Collapsible.Content>
        </Collapsible.Root>
        {canCreateTeam && (
          <div className="absolute h-6 right-0 top-0 p-1">
            <Button
              className="min-w-2 w-6 h-6 bg-transparent rounded flex items-center justify-center border-transparent hover:border-gray-100 border-px border-solid flex-shrink-0"
              onClick={openCreateTeam}
            >
              <div className="px-1">
                <Plus className="h-3 text-gray-400 w-3" weight="bold" />
              </div>
            </Button>
          </div>
        )}
      </div>
    )
  }
)
